import expAnalyticsCollector from 'exp-analytics-collector';

const decorator = (data) => decorator.decorators.reduce((value, d) => d(value), data);
decorator.decorators = [];

const initAnalyticsClient = ({ campaignId = null, mfgId = null }) => {
  if (!window.analytics) {
    // Put it on the window so external scripts can find it
    window.analytics = expAnalyticsCollector?.init({
      campaignId,
      mfgId,
    });
  }

  // We aren't in control of the original analytics init so we stuff in our values here
  if (mfgId && window.analytics.mfgId !== mfgId) {
    window.analytics.mfgId = mfgId;
  }

  if (campaignId && window.analytics.campaignId !== campaignId) {
    window.analytics.campaignId = campaignId;
  }

  return window.analytics;
};

const getAnalyticsClient = (config) => (
  window.analytics || initAnalyticsClient(config)
);

export const pageView = (data, mfgId = null) => {
  getAnalyticsClient({ mfgId }).pv(decorator(data));
};

export const registerAnalyticsDecorator = (fn) => {
  decorator.decorators.push(fn);
};

export const removeDecorator = (fn) => {
  const idx = decorator.decorators.indexOf(fn);
  if (idx !== -1) {
    decorator.decorators.splice(idx, 1);
  }
};

export const sendEvent = (action, data = {}, { mfgId, parentEventId, version } = {}) => {
  const client = getAnalyticsClient({ mfgId });
  if (Array.isArray(data)) {
    return client.sendBulkEvents(action, data.map(decorator));
  }

  return client.sendEvent(action, decorator(data), version, undefined, parentEventId);
};

// Creates a sendEvent with a bespoke decorator
sendEvent.with = (base = {}, baseParams = {}) => (action, data = {}, params = {}) => {
  const fn = typeof base === 'function' ? base : (d = {}) => ({
    ...base,
    ...d,
  });

  return sendEvent(action, Array.isArray(data) ? data.map(fn) : fn(data), {
    ...baseParams,
    ...params,
  });
};
