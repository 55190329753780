import memoize from 'lodash/memoize';

const asDate = (dateable) => {
  const date = dateable instanceof Date ? dateable : new Date(dateable);
  return !dateable || Number.isNaN(date.getTime()) ? null : date;
};

const formatDate = (dateable, format) => {
  const date = asDate(dateable);
  return date ? format.format(date) : null;
};

export const calcDaysUntil = memoize((dateable, until = null) => {
  const d1 = asDate(dateable);
  const d2 = asDate(until) || new Date();
  if (!d1 || !d2) {
    return null;
  }

  const diff = d1.getTime() - d2.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
});

export const formatFullDate = memoize(dateable => formatDate(dateable, Intl.DateTimeFormat()));

export const formatLocaleDate = memoize(dateable => {
  const date = asDate(dateable);
  return date ? date.toLocaleDateString() : null;
});

export const formatShortDate = memoize(dateable => formatDate(dateable, Intl
  .DateTimeFormat(undefined, { month: 'short', day: 'numeric' })));

export const formatShortDateTime = memoize(dateable => `${
  formatDate(dateable, Intl.DateTimeFormat(undefined, { dateStyle: 'short' }))} ${
  formatDate(dateable, Intl.DateTimeFormat(undefined, { timeStyle: 'short' }))}`);
