import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import URI from 'urijs';

export const buildQueryDataMap = (location = document.location) => {
  if (location && location.search) {
    const uri = URI();
    uri.search(location.search);
    return uri.search(true);
  }
  return {};
};

export const buildQueryUrl = (contentEndpoint, queryParams) => {
  const uri = new URI(contentEndpoint);
  if (queryParams) {
    uri.setSearch(queryParams);
  }
  return uri.toString();
};

/**
 * Builds a Uri query string for the given 'params', replacing exiting
 * params or appending new ones.
 *
 * @param params
 * @returns {string}
 */
export const buildUriQueryString = (params, search) => {
  const uri = URI();
  uri.search(search);
  uri.setSearch(params);
  return uri.search();
};

/**
 * This function extracts the specified query params from a React Router location query object.
 * @param paramName The parameter name to extract
 * @param location The history API's location
 * @returns {Array} Array of strings of each value
 */
export const extractQueryParams = (paramName, location) => {
  let result = [];

  if (location && paramName) {
    const namedParam = buildQueryDataMap(location)[paramName];
    if (namedParam instanceof Array) {
      // Works for categoryId=5&categoryId=7 and categoryId=5,7&categoryId=4
      result = result.concat(namedParam.map((el) => el.toString().split(',')).reduce((acc, curr) => acc.concat(curr), []));
    } else if (typeof namedParam === 'string') {
      // Works for categoryId=5,7
      result = result.concat(namedParam.split(','));
    }
  }
  return result;
};

export const getSpecificQueryParam = (queryParam, location) =>
  buildQueryDataMap(location)[queryParam];

export const parseQueryParams = (location = window.location) => Object
  .entries(buildQueryDataMap(location))
  .reduce((map, [param, value]) => ({
    ...map,
    [param]: [value]
      .flat()
      .filter((v) => !!v)
      .map((v) => +v || v),
  }), {});

/**
 * This function recursively returns an array of parameters that have been
 * stored as to preserve their order as {paramName}_0 ... {paramName}_n.
 * @param paramName The base name of the parameters
 * @param i (optional) Index to begin at
 * @param results (optional) Array to add to
 * @returns {Array} Array of strings of each value
 */
export const getOrderedQueryParam = (paramName, i = 0, results = []) => {
  const numberedParam = `${paramName}_${i}`;
  const val = getSpecificQueryParam(numberedParam);
  if (val) {
    return getOrderedQueryParam(paramName, i + 1, results.concat(val));
  }
  return results;
};

/**
 * Hook for storing the very first value across renders
 *
 * @param value The current value
 * @returns The initial value
 */
export const useInitial = (value) => {
  const ref = useRef(value);
  return ref.current;
};

/**
 * Hook for getting the current value of a URL query parameter
 *
 * @param parameter The query parameter
 * @returns The value(s) of the query parameter
 */
export const useQueryParam = (parameter) => {
  const location = useLocation();
  return parseQueryParams(location)[parameter];
};

/**
 * Hook for getting a map of the current URL query parameters
 *
 * @returns Map of query parameters to array of values
 */
export const useQueryParams = () => {
  const location = useLocation();
  return parseQueryParams(location);
};

export default extractQueryParams;
