import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';
import getExpMessage from 'ERC/localization/getExpMessage';

import CardRenderer from '../../../common/CardRenderer';

import './campaignDebug.less';

const CampaignTypes = {
  STANDARD: 'standard',
  MULTI: 'multi',
};

/**
 * Campaign Debug Section
 */
const CampaignDebug = (props) => {
  const reportingType = props.campaign.reportingType || 'EXPERTICITY_COMMON';

  const stats = [
    { label: 'User ID', value: props.userId },
    { label: 'User UUID', value: props.userUuid },
  ];

  if (props.type === CampaignTypes.STANDARD && props.campaign.certification) {
    stats.push(
      { label: 'Certifiable', value: props.campaign.certification.certifiable ? 'Yes' : 'No' },
      { label: 'Certified', value: props.campaign.certification.certified ? 'Yes' : 'No' },
    );
  }

  stats.push(
    { className: 'spacer' },
    { label: 'Campaign ID', value: props.campaign.campaignId || props.campaign.multiBrandCampaignId },
    { label: 'Name', value: props.campaign.name },
    { label: 'Organization', value: props.campaign.orgName },
    { label: 'Organization ID', value: props.campaign.orgId },
    { label: 'Priority', value: props.campaign.priority || 'Default' },
  );

  if (props.type === CampaignTypes.STANDARD) {
    stats.push(
      {
        label: 'Campaign Type',
        value: getExpMessage({
          defaultMessage: props.campaign.type,
          messageKey: `campaignType.${props.campaign.type}`,
        }),
      },
      { label: 'Reporting Type', value: reportingType },
      {
        label: 'Targeting Type',
        value: getExpMessage({
          defaultMessage: props.campaign.targetingType,
          messageKey: `campaignTargetingType.${props.campaign.targetingType}`,
        }),
      },
    );
  }

  const formatDate = (date) => (date && new Date(date).toUTCString()) || '';
  stats.push(
    { label: 'Published Date', value: formatDate(props.campaign.publishedDate) },
    { label: 'Start Date', value: formatDate(props.campaign.startDate) },
    { label: 'End Date', value: formatDate(props.campaign.endDate) },
    {
      label: 'Hero Anchor Position',
      value: getExpMessage({
        defaultMessage: props.campaign.assetAnchorPosition,
        messageKey: `assetAnchorPosition.${props.campaign.assetAnchorPosition}`,
      }),
    },
    { label: 'Description', value: props.campaign.description },
  );

  return (
    <section className={`campaign-section-container ${props.className}`} id="campaign-debug-info">
      <div className="debug-panel">
        <header className="panel-header">
          <h1>Campaign Overview</h1>

          {props.campaign.headline ? (
            <h2>{props.campaign.headline}</h2>
          ) : null}
        </header>
        <article className="panel-body debug-info">
          <div className="debug-details">
            <ul className="debug-stats">
              {stats.map((stat) => (
                <li
                  className={`debug-stat ${stat.className || ''}`}
                  key={`debug-detail-${stat.label}`}
                >
                  {stat.label ? (
                    <>
                      <span className="label">{stat.label}:</span>
                      <span>{stat.value}</span>
                    </>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>

          <div className="debug-cards card-container">
            {CardRenderer.renderCards([{
              avatarImageUrl: props.campaign.orgAvatarImageUrl,
              ...props.campaign,
              analyticsSendEvent: () => {},
              reportingType,
              type: props.type === CampaignTypes.MULTI ? 'MULTI' : 'SINGLE',
            }])}
          </div>
        </article>
      </div>

      {props.tags ? (
        <div className="debug-panel">
          <header className="panel-header">
            <h1>TAGs</h1>
            <h2>{props.tags.length || 0} Matched</h2>
          </header>
          <div className="panel-body debug-info campaign-tags">
            {props.tags.map((tag) => (
              <article className="campaign-tag debug-item" key={tag.tagId}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a name={`tag-${tag.tagId}`} />
                <h1>{tag.name}</h1>
                <div className="debug-details">
                  <ul className="debug-stats">
                    <li className="debug-stat">
                      <span className="label">Tag ID:</span>
                      <span>{tag.tagId}</span>
                    </li>
                    <li className="debug-stat">
                      <span className="label">Tag Type:</span>
                      <ExpLabel
                        defaultMessage={tag.tagType}
                        messageKey={`tagType.${tag.tagType}`}
                      />
                    </li>
                    <li className="debug-stat">
                      <span className="label">Sort Order:</span>
                      <span>{tag.sortOrder}</span>
                    </li>
                    <li className="debug-stat">
                      <span className="label">Status:</span>
                      <ExpLabel
                        defaultMessage={tag.status}
                        messageKey={`tagStatus.${tag.status}`}
                      />
                    </li>
                    <li className="debug-stat">
                      <span className="label">Published Date:</span>
                      <span>{formatDate(tag.publishedTime)}</span>
                    </li>
                  </ul>
                </div>
              </article>
            ))}
          </div>
        </div>
      ) : null}
    </section>
  );
};

CampaignDebug.defaultProps = {
  className: '',
  tags: null,
  type: CampaignTypes.STANDARD,
};

CampaignDebug.propTypes = {
  campaign: PropTypes.object.isRequired,
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf([CampaignTypes.STANDARD, CampaignTypes.MULTI]),
  userId: PropTypes.number,
  userUuid: PropTypes.string,
};

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  tags: state.campaign.tags,
  userId: state.campaign.userId,
  userUuid: state.campaign.userUuid,
});

export default connect(mapStateToProps)(CampaignDebug);
