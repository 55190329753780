import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { pageView } from '../utils/analytics';

/**
 * PageView publishing component
 */
const PageView = (props) => {
  const { pathname, search } = useLocation();

  const check = useMemo(() => {
    // Strip the tailing / from the URL if it's there.
    const base = pathname?.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname;

    if (!props.params?.length) {
      // No query parameters are being considered.
      return base;
    }

    // Filter out any parameters that are not to be included
    const params = new URLSearchParams(search);
    Array.from(params.keys())
      .filter((p) => !props.params.includes(p))
      .forEach((p) => params.delete(p));

    return `${base}?${params.toString()}`;
  }, [pathname, search, props.params]);

  useEffect(() => {
    // Publish a PAGEVIEW event since it has changed.
    pageView(props.data);
  }, [check, props.data]);

  return null;
};

PageView.defaultProps = {
  data: {},
  params: [],
};

PageView.propTypes = {
  data: PropTypes.object,
  params: PropTypes.arrayOf(PropTypes.string),
};

export default PageView;
