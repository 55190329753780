import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OrgAvatar from 'ERC/utilities/components/OrgAvatar';

import { CampaignEvents } from '../../config/analytics';
import { ACTIVITIES } from '../../config/constants';
import { ActivitiesPropType, CampaignPropType } from '../../config/propTypes';
import { sendEvent } from '../../../utils/analytics';
import ChallengeCta from '../activity/challenge/challengeCta';
import StoreCta from '../activity/store/storeCta';
import SurveyCta from '../activity/survey/surveyCta';

import './summary.less';

/**
 * Campaign Summary Component
 *
 * @author jon.morris
 */
class CampaignSummary extends Component {
  // eslint-disable-next-line class-methods-use-this
  sendClickEvent(target, data = {}) {
    sendEvent(CampaignEvents.SUMMARY.CLICK, { target, ...data });
  }

  renderCampaignInfo() {
    const orgAvatar = this.renderOrgAvatar();

    return (
      <div className="campaign-summary-container campaign-summary-info">
        <div className="campaign-details">
          {orgAvatar}
          <div className="campaign-headlines">
            <h2
              className="campaign-brand-name"
              onClick={() => this.sendClickEvent('brandName')}
            >
              {this.props.campaign.communityPageLinkUrl ? (
                <a href={this.props.campaign.communityPageLinkUrl}>
                  {this.props.campaign.orgName}
                </a>
              ) : this.props.campaign.orgName}
            </h2>
            <h1
              className="campaign-headline"
              onClick={() => this.sendClickEvent('headline')}
            >
              {this.props.campaign.headline}
            </h1>
          </div>
        </div>
        <p
          className="campaign-description"
          onClick={() => this.sendClickEvent('description')}
        >
          {this.props.campaign.description}
        </p>
      </div>
    );
  }

  renderOrgAvatar() {
    if (!this.props.campaign.orgAvatarImageUrl) {
      return null;
    }

    if (this.props.campaign.communityPageLinkUrl) {
      return (
        <a
          className="campaign-brand-avatar"
          href={this.props.campaign.communityPageLinkUrl}
          onClick={() => this.sendClickEvent('brandAvatar')}
        >
          <OrgAvatar
            alt={this.props.campaign.orgName}
            url={this.props.campaign.orgAvatarImageUrl}
          />
        </a>
      );
    }

    return (
      <span
        className="campaign-brand-avatar"
        onClick={() => this.sendClickEvent('brandAvatar')}
      >
        <img src={this.props.campaign.orgAvatarImageUrl} alt={this.props.campaign.orgName} />
      </span>
    );
  }

  renderPrimaryCta() {
    const { campaign, currentActivity } = this.props;
    switch (currentActivity?.type) {
      case ACTIVITIES.CHALLENGE:
        return this.renderChallengeCta(currentActivity);
      case ACTIVITIES.SURVEY:
        return this.renderSurveyCta(currentActivity);
      default:
        // Standard Learn/Buy Campaign CTA
        if (campaign.storeData || campaign.learnData) {
          return (
            <StoreCta
              campaign={campaign}
              previewKey={this.props.previewKey}
              onCtaClick={(target, data = {}) => this.sendClickEvent(target, data)}
            />
          );
        }
    }

    // No Primary CTA if there isn't activities or shopping
    return null;
  }

  renderChallengeCta(activity) {
    return (
      <ChallengeCta
        activity={activity}
        onCtaClick={() => this.sendClickEvent(ACTIVITIES.CHALLENGE)}
      />
    );
  }

  renderSurveyCta(activity) {
    return (
      <SurveyCta
        activity={activity}
        onCtaClick={() => this.sendClickEvent(ACTIVITIES.SURVEY)}
      />
    );
  }

  render() {
    const campaignInfo = this.renderCampaignInfo();
    const primaryCta = this.renderPrimaryCta();

    return (
      <section id="campaign-summary" className="campaign-section">
        <div className="campaign-section-container">
          <div className="campaign-summary-wrap">
            {campaignInfo}
            {primaryCta}
          </div>
        </div>
      </section>
    );
  }
}

CampaignSummary.defaultProps = {
  currentActivity: null,
  previewKey: null,
};

CampaignSummary.propTypes = {
  campaign: CampaignPropType.isRequired,
  currentActivity: ActivitiesPropType,
  previewKey: PropTypes.string,
};

export default CampaignSummary;
