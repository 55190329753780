export const ACTIVITIES = {
  CHALLENGE: 'challenge',
  CLICK: 'click',
  CONTENT_UPLOAD: 'content_upload',
  RECOMMENDATION: 'recommendation',
  RECRUITMENT: 'recruitment',
  SHIPPING: 'shipping',
  SOCIAL_SHARE: 'social_share',
  STORE: 'store',
  SURVEY: 'survey',
  TRAINING: 'training',
  WAIT: 'wait',
};

export const CTAS = {
  VISIT_STORE: 'Visit Store',
};

export const REPORTING_TYPES = {
  ACTIVITY: 'ACTIVITY',
  CHALLENGE: 'CHALLENGE',
  COMMON: 'EXPERTICITY_COMMON',
  CREATE_BUZZ: 'CREATE_BUZZ',
  DRIVE_TRAFFIC: 'DRIVE_TRAFFIC',
  GENERATE_AND_SHARE: 'GENERATE_AND_SHARE',
  GENERATE_CONTENT: 'GENERATE_CONTENT',
  GENERIC: 'GENERIC',
  LEARN: 'LEARN',
  MICRO_LEARN: 'MICRO_LEARN',
  RECRUITED_ACTIVITIES: 'RECRUITED_ACTIVITIES',
  SURVEY: 'SURVEY',
  UGC_DISPLAY: 'UGC_DISPLAY',
};

export const GENERIC_ACTIVITY_STATUS = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
};

export const RECRUITMENT_ACTIVITY_STATUS = {
  APPROVED: 'APPROVED',
  DISABLED: 'DISABLED', // not an actual status but a computed state
  NOT_STARTED: 'NOT_STARTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const SHIPPING_ACTIVITY_STATUS = {
  RECEIVED: 'RECEIVED',
  SHIPMENT_PREPARING: 'SHIPMENT_PREPARING',
  SHIPPED: 'SHIPPED',
};
