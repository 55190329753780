import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';

import { ChallengeActivityPropType } from '../../../config/propTypes';
import { getChallenge } from '../../../redux/campaignSlice';
import { formatShortDate } from '../../../../utils/utils';
import ActivityCta from '../activityCta';

/**
 * Challenge Activity CTA
 */
const ChallengeCta = (props) => {
  const { campaign } = useSelector((state) => state.campaign);
  const challenge = useSelector((state) => getChallenge(state,
    props.activity?.activity?.challengeId));

  if (!challenge) {
    // Render nothing until the challenge is loaded
    return null;
  }

  return (
    <ActivityCta
      className={props.className}
      message={challenge.qualifying ? (
        props.activity.activity.dueDate ? (
          <ExpLabel
            defaultMessage="Score at least {score} points by {date} for a special reward."
            messageKey="campaign.challenge.description-qualifying-due"
            namespace="messages/campaignMessages"
            values={{
              date: formatShortDate(props.activity.activity.dueDate),
              score: challenge.qualifyingScore,
            }}
          />
        ) : (
          <ExpLabel
            defaultMessage="Score at least {score} points for a special reward."
            messageKey="campaign.challenge.description-qualifying"
            namespace="messages/campaignMessages"
            values={{ score: challenge.qualifyingScore }}
          />
        )
      ) : (
        props.activity.activity.dueDate ? (
          <ExpLabel
            defaultMessage="Reach the top of the leaderboard by {date}."
            messageKey="campaign.challenge.description-due"
            namespace="messages/campaignMessages"
            values={{ date: formatShortDate(props.activity.activity.dueDate) }}
          />
        ) : (
          <ExpLabel
            defaultMessage="Reach the top of the leaderboard."
            messageKey="campaign.challenge.description"
            namespace="messages/campaignMessages"
          />
        )
      )}
      title={(
        <ExpLabel
          defaultMessage="Prove your expertise. Rank Up."
          messageKey="campaign.challenge.title"
          namespace="messages/campaignMessages"
        />
      )}
      type={props.activity.type}
    >
      {props.activity.engagement.enabled ? (
        <a
          className="cta-button btn-primary"
          href={`/challenge/${campaign.orgKey}/${props.activity.activity.challengeId}/play?campaignId=${campaign.campaignId}`}
          onClick={() => {
            props.onCtaClick();
          }}
        >
          <ExpLabel
            defaultMessage="Find an Opponent"
            messageKey="campaign.challenge.find-opponent"
            namespace="messages/campaignMessages"
          />
        </a>
      ) : null}
    </ActivityCta>
  );
};

ChallengeCta.defaultProps = {
  className: null,
  onCtaClick: () => {},
};

ChallengeCta.propTypes = {
  activity: ChallengeActivityPropType.isRequired,
  className: PropTypes.string,
  onCtaClick: PropTypes.func,
};

export default ChallengeCta;
