import Axios from 'axios';
import validator from 'validator';

const allowedTypes = ['get', 'delete', 'post', 'put'];
const request = Axios.create({});

/** @returns {import('axios').AxiosPromise} */
const xhr = (url, type, requestBody, config) => {
  const msg = `Xhr expected type of: ${allowedTypes} but found type ${type}`;
  const data = requestBody || {};
  const cfg = config || {};

  // Add cache busting on all GET requests. This fixes GET caching in IE.
  // todo: still necessary?
  if (type === 'get') {
    const headers = cfg.headers || {};
    headers['Cache-Control'] = 'no-cache';
    headers.Pragma = 'no-cache';
    cfg.headers = headers;
  }

  if (validator.isIn(type, allowedTypes)) {
    if (type === 'post' || type === 'put') {
      return request[type](url, data, cfg);
    }

    return request[type](url, cfg);
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ data: msg });
};

// Create aliases off of the xhr object for each of the methods
allowedTypes.forEach((method) => {
  xhr[method] = (url, requestBody) => xhr(url, method, requestBody);
});

export default xhr;
