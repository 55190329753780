import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Carousel from 'ERC/carousel/Carousel';
import ExpLabel from 'ERC/localization/ExpLabel';

import { sendEvent } from '../../../../utils/analytics';
import { fetchFavoritesContent, fetchTopMembers } from '../../../redux/actions';
import AcquisitionTopics from './acquisitionTopics';
import BrandCard from '../../../../common/card/BrandCard';
import CardRenderer from '../../../../common/CardRenderer';
import FollowedBrandsLoader from '../../../../common/FollowedBrandsLoader';
import { PageTypesByType } from '../../../PageTypes';
import PopularProducts from '../../popularProducts';
import ReviewCard from '../../reviewCard';
import SeeAllCard from '../../../../homepage/components/SeeAllCard';

import './acquisitionFavorites.less';

export const hasFavoritesContent = (state) =>
  (state.community.pageType === PageTypesByType.MANUFACTURER.type
    ? state.community.topSellers?.length
    : state.community.teaserBrands?.length)
  || state.community.recentBrandReviews?.length
  || state.community.topics?.length;

const AcquisitionFavorites = (props) => {
  const dispatch = useDispatch();

  const {
    brandsHaveNames,
    brandsTitle,
    campaignsFooter,
    campaignsSeeAllLink,
    campaignsTitle,
    handleCampaignEvent,
    handleRemoveSurveyCard,
    membersFooter,
    membersTitle,
    membersToShow,
    noTitle,
    reviewsTitle,
    showSeeAllBrands,
  } = props;

  const campaigns = useSelector((state) => state.community.campaigns);
  const hasContent = useSelector((state) => hasFavoritesContent(state));
  const includeFeaturedProducts = useSelector((state) => state.community.includeFeaturedProducts);
  const isMember = useSelector((state) => state.community.isMember);
  const memberLabel = useSelector((state) => state.community.memberLabel);
  const members = useSelector((state) => state.community.topMembers);
  const {
    name: orgName,
    orgId,
  } = useSelector((state) => state.community.org || {});
  const pageId = useSelector((state) => state.community.id);
  const pageType = useSelector((state) => state.community.pageType);
  const showReviews = useSelector((state) => state.community.showReviews);
  const recentBrandReviews = useSelector((state) => state.community.recentBrandReviews);
  const teamId = useSelector((state) => state.community.teamId);
  const teamShortName = useSelector((state) => state.community.team?.shortName);
  const teaserBrands = useSelector((state) => state.community.teaserBrands);
  const topics = useSelector((state) => state.community.topics);
  const topSellers = useSelector((state) => state.community.topSellers);

  const isBrandPage = pageType === PageTypesByType.MANUFACTURER.type;
  const displayName = teamShortName || orgName;

  const hasCampaigns = !!campaigns?.length;
  const hasMembers = !!members?.list?.length;
  const hasRecentReviews = !!recentBrandReviews?.length;
  const hasTeaserBrands = !!teaserBrands?.length;
  const hasTopics = !!topics?.length;
  const hasTopSellers = !!topSellers?.length;

  useEffect(() => {
    if (!hasContent) {
      dispatch(fetchFavoritesContent());
    }
  }, [dispatch, hasContent]);

  useEffect(() => {
    if (!members?.loading && !members?.list && membersToShow && isMember) {
      dispatch(fetchTopMembers({
        page: 0,
        size: membersToShow,
      }));
    }
  }, [dispatch, isMember, members, membersToShow]);

  const handleTeaserBrandClick = useCallback((teaserBrand, index) => {
    sendEvent('ACQUISITION_PAGE_TEASER_BRAND_CLICK', {
      index,
      orgId,
      orgName,
      pageId,
      teamId,
      teaserOrgId: teaserBrand.orgId,
      teaserOrgName: teaserBrand.orgName,
    });
  }, [orgId, orgName, pageId, teamId]);

  if (!hasContent && !members?.totalCount) {
    return null;
  }

  return (
    <div className="acquisition-favorites">
      {!noTitle && (
        <h3 className="faves-header">
          {(isBrandPage ? 'Discover expert favorites' : `Discover ${displayName} ${memberLabel} favorites`)}
        </h3>
      )}
      {isBrandPage && hasTopSellers && includeFeaturedProducts && (
        // Top Sellers exist elsewhere on non-brand pages
        <PopularProducts
          className="fave-section basic-item-section"
          title={(
            <ExpLabel
              className="content-section"
              defaultMessage="Best sellers"
              messageKey="community.topSellers.title"
            />
          )}
        />
      )}
      {hasTeaserBrands && (
        <div className="fave-section basic-item-section teaser-brands">
          <Carousel
            className="card-carousel teaser-brands-carousel"
            scroll
            title={brandsTitle || 'Popular deals'}
            footer={showSeeAllBrands && (
              <ExpLabel
                className="link-primary"
                defaultMessage="See all"
                href="/home/brands"
                messageKey="community.team.favorites.seeAll"
                tagName="a"
              />
            )}
          >
            {teaserBrands?.map((teaserBrand, index) => (
              <BrandCard
                brand={teaserBrand}
                hideName={!brandsHaveNames}
                key={teaserBrand.orgId}
                onClick={() => handleTeaserBrandClick(teaserBrand, index)}
                size="sm"
              />
            ))}
          </Carousel>
        </div>
      )}
      {hasCampaigns && (
        <div className="fave-section campaigns">
          <FollowedBrandsLoader />
          <Carousel
            className="campaigns-carousel"
            footer={campaignsFooter}
            scroll
            title={campaignsTitle}
          >
            {CardRenderer.renderCards(campaigns.slice(0, 11), {
              analyticsSendEvent: handleCampaignEvent,
              removeSurveyCard: handleRemoveSurveyCard,
            })}
            {campaigns.length > 11 && (
              <SeeAllCard
                className="campaign-card"
                to={campaignsSeeAllLink}
              />
            )}
          </Carousel>
        </div>
      )}
      {hasTopics ? (
        <AcquisitionTopics displayName={displayName} topics={topics} />
      ) : null}
      {hasMembers ? (
        <div className="fave-section members">
          <Carousel
            className="basic-item-carousel members-carousel"
            footer={membersFooter}
            scroll
            title={membersTitle || (
              <ExpLabel
                defaultMessage="Community Members"
                messageKey="community.members.community"
              />
            )}
          >
            {members.list
              .slice(0, props.membersToShow)
              .map((member, index) => (
                <a
                  className="basic-item member-card"
                  data-index={index}
                  data-useruuid={member.uuid}
                  href={member.profileLink}
                  key={member.uuid}
                  onClick={() => {
                    sendEvent('COMMUNITY_MEMBER_CLICK', {
                      index,
                      followed: member.followed,
                      privateProfile: member.privateProfile,
                      source: 'carousel',
                      targetUserUuid: member.uuid,
                    });
                  }}
                >
                  <img
                    alt={member.displayName}
                    className="basic-item-image user-avatar avatar"
                    src={member.avatar}
                  />
                  <span className="basic-item-description user-name">
                    {member.displayName}
                  </span>
                </a>
              ))}
          </Carousel>
        </div>
      ) : null}
      {showReviews && hasRecentReviews && (
        <div className="fave-section recent-review">
          <Carousel
            className="recent-reviews-carousel"
            scroll
            title={reviewsTitle || 'Latest expert reviews'}
          >
            {recentBrandReviews
              .map((review, index) => (
                <ReviewCard
                  className="recent-review-card"
                  index={index}
                  key={review.id}
                  review={review}
                />
              ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

AcquisitionFavorites.defaultProps = {
  brandsHaveNames: false,
  brandsTitle: undefined,
  campaignsFooter: undefined,
  campaignsSeeAllLink: undefined,
  campaignsTitle: undefined,
  handleCampaignEvent: () => {},
  handleRemoveSurveyCard: () => {},
  membersFooter: undefined,
  membersTitle: undefined,
  membersToShow: 0,
  noTitle: false,
  reviewsTitle: undefined,
  showSeeAllBrands: false,
};

AcquisitionFavorites.propTypes = {
  brandsHaveNames: PropTypes.bool,
  brandsTitle: PropTypes.string,
  campaignsFooter: PropTypes.any,
  campaignsSeeAllLink: PropTypes.string,
  campaignsTitle: PropTypes.string,
  handleCampaignEvent: PropTypes.func,
  handleRemoveSurveyCard: PropTypes.func,
  membersFooter: PropTypes.any,
  membersTitle: PropTypes.any,
  membersToShow: PropTypes.number,
  noTitle: PropTypes.bool,
  reviewsTitle: PropTypes.string,
  showSeeAllBrands: PropTypes.bool,
};

export default AcquisitionFavorites;
