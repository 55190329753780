import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Carousel from 'ERC/carousel/Carousel';
import decodeHTML from 'ERC/utilities/decodeHTML';

import { fetchTopSellers } from '../redux/actions';
import { sendEvent } from '../../utils/analytics';

const PopularProducts = (props) => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.community.orgId);
  const pageId = useSelector((state) => state.community.id);
  const teamId = useSelector((state) => state.community.teamId);
  const topSellers = useSelector((state) => state.community.topSellers);

  useEffect(() => {
    if (!topSellers) {
      dispatch(fetchTopSellers({ orgId }));
    }
  }, [dispatch, orgId, topSellers]);

  const handleTopSellerClick = useCallback((e, topSeller, index) => {
    sendEvent('TRENDING_PRODUCT_CARD_CLICK', {
      index,
      name: topSeller.text,
      orgId,
      pageId,
      productCode: topSeller.metadata.productCode,
      target: e?.target?.className === 'best-seller-image' ? 'image' : 'name',
      teamId,
    });
  }, [orgId, pageId, teamId]);

  if (!topSellers?.length) {
    return null;
  }
  return (
    <div className={`top-sellers${props.className ? ` ${props.className}` : ''}`}>
      <Carousel
        className="basic-item-carousel top-sellers-carousel"
        scroll
        title={props.title}
      >
        {topSellers?.map((topSeller, index) => (

          <a
            className="basic-item best-seller"
            href={topSeller.url}
            key={topSeller.metadata?.productCode}
            onClick={(e) => handleTopSellerClick(e, topSeller, index)}
          >
            <img alt="" className="basic-item-image best-seller-image" src={topSeller.imageUrl} />
            <span className="basic-item-description best-seller-description">
              {decodeHTML(topSeller.text)}
            </span>
          </a>
        ))}
      </Carousel>
    </div>
  );
};

PopularProducts.defaultProps = {
  className: 'undefined',
  title: null,
};

PopularProducts.propTypes = {
  className: PropTypes.string,
  title: PropTypes.element,
};

export default PopularProducts;
