import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';
import ProgressBar from 'ERC/progress/ProgressBar';

import { calcDaysUntil, formatFullDate } from '../../../../utils/utils';
import { SurveyActivityPropType } from '../../../config/propTypes';

const ReportActivityStatus = (props) => {
  const {
    activity: { activity, statusCounts = {} },
  } = props;

  const user = useSelector((state) => state.campaign.user);

  const daysRemaining = calcDaysUntil(activity.dueDate);
  return (
    <div className={`report-activity-details${props.className ? ` ${props.className}` : ''}`}>
      {props.showAvatar && user?.avatar ? (
        <div className="user-avatar">
          <img alt={user.name} className="avatar" src={user.avatar} />
          <i className="exp-ux-mini exp-ux-bolt bolt-icon" />
        </div>
      ) : null}

      <div className="engagements-status">
        <div className="activity-status">
          <div className="activity-stat progress-count">
            {statusCounts.APPROVED || '--'}
            {activity.targetNumberOfSubmissions ? ` / ${activity.targetNumberOfSubmissions}` : null}
          </div>

          {activity.dueDate ? (
            <div className="activity-stat time-remaining">
              {daysRemaining === 1 ? (
                <ExpLabel
                  defaultMessage="{days} day left"
                  messageKey="campaign.activity.engagements.day-remaining"
                  namespace="messages/campaignMessages"
                  values={{ days: daysRemaining }}
                />
              ) : daysRemaining > 1 ? (
                <ExpLabel
                  defaultMessage="{days} days left"
                  messageKey="campaign.activity.engagements.days-remaining"
                  namespace="messages/campaignMessages"
                  values={{ days: daysRemaining }}
                />
              ) : (
                <ExpLabel
                  defaultMessage="Submissions closed on {dueDate}"
                  messageKey="campaign.activity.engagements.due-past"
                  namespace="messages/campaignMessages"
                  values={{ dueDate: formatFullDate(activity.dueDate) }}
                />
              )}
            </div>
          ) : null}
        </div>
        {activity.targetNumberOfSubmissions ? (
          <div className="activity-progress">
            <ProgressBar
              currentStep={statusCounts.APPROVED}
              totalSteps={activity.targetNumberOfSubmissions}
            />
            <div className="progress-pct">
              {Math.round(((statusCounts.APPROVED || 0) / activity
                .targetNumberOfSubmissions) * 100)}%
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ReportActivityStatus.defaultProps = {
  className: '',
  showAvatar: false,
};

ReportActivityStatus.propTypes = {
  activity: SurveyActivityPropType.isRequired,
  className: PropTypes.string,
  showAvatar: PropTypes.bool,
};

export default ReportActivityStatus;
