import { useFollowedBrands } from 'ERC/following/hooks';

/**
 * Followed Brands Loader component
 * - to be used when the followed brands needs to be loaded conditionally (i.e. if authenticated)
 */
const FollowedBrandsLoader = () => {
  // Ensure the followed users are loaded
  useFollowedBrands();
  return null;
};

export default FollowedBrandsLoader;
