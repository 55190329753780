import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'ERC/carousel/Carousel';
import ExpLabel from 'ERC/localization/ExpLabel';
import TopicCard from 'ERC/topic/TopicCard';
import { useIsMediaSM } from 'ERC/hooks';

import { sendEvent } from '../../../../utils/analytics';

import './acquisitionTopics.less';

const INITIAL_VISIBLE = 3;
const PAGE_SIZE = 12;

const AcquisitionTopics = (props) => {
  const isSM = useIsMediaSM();
  const [numVisible, setNumVisible] = useState(INITIAL_VISIBLE);
  useEffect(() => {
    // Reset the number visible on mobile when the breakpoint is tripped
    setNumVisible(INITIAL_VISIBLE);
  }, [isSM]);

  const visible = useMemo(() => (isSM ? props.topics : props.topics
    .slice(0, numVisible)), [isSM, numVisible, props.topics]);

  return (
    <div className="fave-section topics">
      <Carousel
        className="topic-carousel"
        footer={visible.length < props.topics.length ? (
          <button
            className="btn-see-more btn-outline"
            onClick={() => {
              setNumVisible(Math.min(numVisible + PAGE_SIZE, props.topics.length));
            }}
            type="button"
          >
            <ExpLabel
              defaultMessage="Show more topics"
              messageKey="community.team.topics.seeMore"
            />
          </button>
        ) : null}
        scroll
        title={(
          <ExpLabel
            defaultMessage="Popular topics with {displayName}"
            messageKey="community.team.topics.title"
            values={{ displayName: props.displayName }}
          />
        )}
      >
        {visible.map((topic, index) => (
          <TopicCard
            key={topic.id}
            onClick={() => {
              sendEvent('TOPIC_CLICK', {
                index,
                source: 'community',
                targetId: topic.id,
                targetName: topic.name,
              });
            }}
            topic={topic}
          />
        ))}
      </Carousel>
    </div>
  );
};

AcquisitionTopics.defaultProps = {
  displayName: '',
};

AcquisitionTopics.propTypes = {
  displayName: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  })).isRequired,
};

export default AcquisitionTopics;
