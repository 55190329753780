import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { RECOMMENDATION_SOURCE, STARS_MAX } from 'ERC/recommendations/utils/constants';
import Carousel from 'ERC/carousel/Carousel';
import ExpLabel from 'ERC/localization/ExpLabel';
import getNativeHook from 'ERC/utilities/getNativeHook';
import ProductPicker from 'ERC/productPicker/ProductPicker';
import RecommendationEdit from 'ERC/recommendations/RecommendationEdit';
import Star from 'ERC/utilities/components/Star';
import { useIsMediaSM } from 'ERC/hooks';

import CommunityActionsDispatch from '../redux/actions';
import { sendEvent } from '../../utils/analytics';
import ErrorBoundary from '../../common/ErrorBoundary';
import ReviewCard from './reviewCard';

import './containRecentReviews.less';

const MOBILE_INITIAL_PAGE_SIZE = 3;
const MOBILE_PAGE_SIZE = 10;

/**
 * Recent Brand Reviews Component
 */
// exported separately from connected default for use in BrandsPage
export const ContainRecentReviews = (props) => {
  const {
    cloudinaryCloudName,
    fetchHasReviewableProducts,
    fetchRecentBrandReviews,
    hasReviewableProducts,
    isAuthenticated,
    org: {
      name: orgName,
      orgId,
    },
    recentBrandReviews,
    updateRecentBrandReviews,
    userId,
  } = props;

  const isCarouselSized = useIsMediaSM();
  const [mobileReviewCount, setMaxReviews] = useState(MOBILE_INITIAL_PAGE_SIZE);
  const [showProductPicker, setShowProductPicker] = useState(false);
  const [selectedRecoProduct, setSelectedRecoProduct] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHasReviewableProducts({ orgId });
    }
  }, [fetchHasReviewableProducts, isAuthenticated, orgId]);

  useEffect(() => {
    if (!recentBrandReviews) {
      fetchRecentBrandReviews({ orgId });
    }
  }, [fetchRecentBrandReviews, orgId, recentBrandReviews]);

  const handleCloseProductPicker = useCallback(() => {
    setShowProductPicker(false);
  }, []);

  const handleCloseRecoModal = useCallback(() => {
    setSelectedRecoProduct(null);
  }, []);

  const handleProductSelected = useCallback((product) => {
    const nativeHook = getNativeHook();

    if (nativeHook) {
      const { productCode, imageUrl, productName } = product;

      nativeHook.postMessage(JSON.stringify({
        action: 'CREATE_REVIEW',
        data: {
          orgId,
          productCode,
          productImage: imageUrl,
          productName,
        },
      }));
    } else {
      setSelectedRecoProduct(product);
    }
  }, [orgId]);

  const handleReviewPromptClick = useCallback(() => {
    sendEvent('BRAND_PAGE_SHARE_REC_CLICK', { orgId, orgName });
    setShowProductPicker(true);
  }, [orgId, orgName]);

  const handleReviewSubmit = useCallback(({ review }) => {
    const filteredReviews = (recentBrandReviews || []).filter((rec) => rec.id !== review.id);
    const updatedRecentReviews = [review, ...filteredReviews];

    setSelectedRecoProduct(null);
    updateRecentBrandReviews(updatedRecentReviews);
  }, [recentBrandReviews, updateRecentBrandReviews]);

  const handleSeeMoreClick = useCallback(() => {
    setMaxReviews((max) => max + MOBILE_PAGE_SIZE);
  }, []);

  if (!recentBrandReviews?.length && !hasReviewableProducts) {
    return null;
  }

  return (
    <ErrorBoundary>
      <section className="reviews-area">
        {!!recentBrandReviews?.length && (
          <section className="contain-recent-reviews container">
            <Carousel
              footer={!isCarouselSized && recentBrandReviews?.length > mobileReviewCount && (
                <ExpLabel
                  className="see-more-btn btn-outline"
                  defaultMessage="See more reviews"
                  messageKey="community.brandReviews.seeMore"
                  onClick={handleSeeMoreClick}
                  tagName="button"
                  type="button"
                />
              )}
              title={(
                <ExpLabel
                  className="content-section"
                  defaultMessage="Latest expert reviews"
                  messageKey="community.brandReviews.title"
                />
              )}
            >
              {recentBrandReviews
                .slice(0, isCarouselSized ? undefined : mobileReviewCount)
                .map((review, index) => (
                  <ReviewCard
                    className="recent-review-card"
                    index={index}
                    key={review.id}
                    listMode={!isCarouselSized}
                    review={review}
                  />
                ))}
            </Carousel>
          </section>
        )}
        {hasReviewableProducts && (
          <section className="recommend-prompt-section">
            <div className="stars">
              {Array.from(Array(STARS_MAX).keys()).map(index => (
                <Star key={index} filled />
              ))}
            </div>
            <div className="prompt-text-wrapper">
              <ExpLabel
                className="recommend-prompt-title"
                defaultMessage="Review your favorite {brand} products"
                messageKey="community.recommendPrompt.title"
                values={{ brand: orgName }}
              />
              <ExpLabel
                className="recommend-prompt-body"
                defaultMessage="When it comes to buying advice, people trust experts more than just about anyone."
                messageKey="community.recommendPrompt.body"
              />
            </div>
            <button
              className="btn-outline recommend-prompt-cta"
              onClick={handleReviewPromptClick}
              type="button"
            >
              <ExpLabel
                defaultMessage="Write a review"
                messageKey="community.recommendPrompt.cta"
              />
            </button>
          </section>
        )}
      </section>
      {showProductPicker && (
        <ProductPicker
          closeModal={handleCloseProductPicker}
          handleSelectedProduct={handleProductSelected}
          orgIds={[orgId]}
          orgRestricted
          sendAnalyticsEvent={sendEvent}
        />
      )}
      {selectedRecoProduct && (
        <RecommendationEdit
          cloudinaryCloudName={cloudinaryCloudName}
          onCloseClick={handleCloseRecoModal}
          onSubmitReviewSuccess={handleReviewSubmit}
          orgId={parseInt(selectedRecoProduct.orgId, 10)}
          orgName={selectedRecoProduct.orgName}
          productCode={selectedRecoProduct.productCode}
          productImageUrl={selectedRecoProduct.imageUrl}
          productName={selectedRecoProduct.productName}
          sendAnalyticsEvent={sendEvent}
          source={RECOMMENDATION_SOURCE.SOURCE_BRAND_PAGE}
          userId={userId}
        />
      )}
    </ErrorBoundary>
  );
};

ContainRecentReviews.propTypes = {
  cloudinaryCloudName: PropTypes.string,
  fetchHasReviewableProducts: PropTypes.func,
  fetchRecentBrandReviews: PropTypes.func,
  hasReviewableProducts: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  org: PropTypes.shape({
    name: PropTypes.string.isRequired,
    orgId: PropTypes.number.isRequired,
  }).isRequired,
  recentBrandReviews: PropTypes.array,
  updateRecentBrandReviews: PropTypes.func,
  userId: PropTypes.number,
};

const mapStateToProps = state => ({
  cloudinaryCloudName: state.community.cloudinaryCloudName,
  hasReviewableProducts: state.community.hasReviewableProducts,
  isAuthenticated: state.community.isAuthenticated,
  org: state.community.org,
  recentBrandReviews: state.community.recentBrandReviews,
  userId: state.community.userId,
});

export default connect(mapStateToProps, CommunityActionsDispatch)(ContainRecentReviews);
