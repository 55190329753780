import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FollowUser from 'ERC/following/FollowUser';
import ExpLabel from 'ERC/localization/ExpLabel';

import { sendEvent } from '../../../utils/analytics';
import InfiniteScrollingList from '../../../common/InfiniteScrollingList';

import './memberList.less';

/**
 * Member List Component
 */
const MemberList = (props) => {
  const [collapsed, setCollapsed] = useState(props.layout === 'gallery');

  useEffect(() => {
    if (!props.members.loading && (!props.members.list || props.members.stale)) {
      // Fetch at least the first page of members
      props.fetchPage({
        page: 0,
        size: props.members.pageSize,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.members.list?.length) {
    // Nothing to show
    return null;
  }

  const collapsedItemsToShow = props.maxItems || props.members.totalCount;
  let usersToRender = props.members.list;
  if (props.layout === 'gallery' &&
    (props.members.hasMore || (collapsed && props.members.totalCount > collapsedItemsToShow))) {
    usersToRender = props.members.list.slice(0, collapsedItemsToShow - 1);
  }

  const showToggle = props.layout === 'gallery' && props.members.totalCount > collapsedItemsToShow;

  const renderItem = (member, index) => (
    <div
      className="list-item list-user"
      data-index={index}
      data-useruuid={member.uuid}
      key={member.uuid}
    >
      <a
        href={member.profileLink}
        onClick={() => sendEvent('COMMUNITY_MEMBER_CLICK', {
          index,
          followed: member.followed,
          privateProfile: member.privateProfile,
          source: props.source,
          targetUserUuid: member.uuid,
        })}
      >
        <img
          alt={member.displayName}
          className="avatar item-avatar user-avatar"
          src={member.avatar}
        />
        <span className="item-details">
          <span className="item-detail item-name">{member.displayName}</span>
          {props.layout === 'list' ? (
            <>
              {member.privateProfile || !member.location ? null : (
                <span className="item-detail user-location">{member.location}</span>
              )}
              {member.communityMemberSince ? (
                <ExpLabel
                  className="item-detail user-member-since"
                  defaultMessage="Member since {date}"
                  messageKey="community.members.member-since"
                  values={{
                    date: new Date(member.communityMemberSince).toLocaleDateString(),
                  }}
                />
              ) : null}
            </>
          ) : null}
        </span>
      </a>

      {props.layout === 'list' ? (
        <FollowUser
          myUserUuid={props.myUuid}
          onClick={({ isFollowing }) => {
            sendEvent('FOLLOW_USER_TOGGLE', {
              followed: !isFollowing,
              index,
              source: 'community',
            });
          }}
          privateProfile={member.privateProfile}
          userUuid={member.uuid}
        />
      ) : null}
    </div>
  );

  return (
    <div
      className={`members-section ${props.source}-members${
        props.className ? ` ${props.className}` : ''}`}
    >
      <div className="members-section-header">
        {props.heading ? (
          <h3 className="members-section-heading">
            {props.heading}
          </h3>
        ) : null}

        {props.members.totalCount ? (
          <ExpLabel
            className="members-section-total"
            defaultMessage={props.members.totalCount > 1 ? '{count} Members' : '{count} Member'}
            messageKey={props.members.totalCount > 1 ? 'community.members.count' : 'community.member.count'}
            values={{ count: props.members.totalCount }}
          />
        ) : null}
      </div>

      <div
        className="member-list"
        data-collapsed={collapsed}
        data-layout={props.layout}
        data-multirow={props.multiRow}
      >
        {collapsed ? usersToRender.map(renderItem) : (
          <InfiniteScrollingList
            hasMore={props.members.hasMore}
            items={usersToRender}
            loading={props.members.loading}
            loadMore={() => props.fetchPage({
              page: props.members.list ? props.members.currentPage + 1 : 0,
              size: props.members.pageSize,
            })}
            renderItem={renderItem}
          />
        )}

        {showToggle ? (
          <div className="list-item list-toggle-item">
            {props.expandedListUrl ? (
              <Link
                className="list-toggle"
                to={props.expandedListUrl}
                onClick={() => {
                  sendEvent('COMMUNITY_MEMBERS_TOGGLE_CLICK', {
                    collapsed,
                    source: props.source,
                    totalCount: props.members.totalCount,
                  });
                }}
              >
                <ExpLabel
                  defaultMessage="See All"
                  messageKey="community.members.see-all"
                />
              </Link>
            ) : props.expandable ? (
              <button
                className={`list-toggle ${
                  collapsed ? 'expand' : 'collapse'}${
                  props.members.loading ? ' loading' : ''}`}
                disabled={props.members.loading}
                onClick={() => {
                  sendEvent('COMMUNITY_MEMBERS_TOGGLE_CLICK', {
                    collapsed,
                    source: props.source,
                    totalCount: props.members.totalCount,
                  });

                  setCollapsed(!collapsed);
                }}
                type="button"
              >
                {props.members.loading ? (
                  <i className="exp-ux-spinner exp-ux-pulse exp-ux-large" />
                ) : (collapsed ? (
                  <ExpLabel
                    defaultMessage="See All"
                    messageKey="community.members.see-all"
                  />
                ) : (
                  <ExpLabel
                    defaultMessage="See Fewer"
                    messageKey="community.members.see-fewer"
                  />
                ))}
              </button>
            ) : (
              <ExpLabel
                className="list-toggle list-info"
                defaultMessage="+{count} More"
                messageKey="community.members.more-count"
                values={{ count: props.members.totalCount - usersToRender.length }}
              />
            )}
          </div>
        ) : null}

        {props.layout === 'list' && props.members.loading ? (
          <div className="list-item list-item-loading">
            <i className="exp-ux-spinner exp-ux-pulse exp-ux-large" />
          </div>
        ) : null}
      </div>

      {showToggle && props.expandedListUrl ? (
        <Link
          className="expand-link"
          to={props.expandedListUrl}
          onClick={() => {
            sendEvent('COMMUNITY_MEMBERS_TOGGLE_CLICK', {
              collapsed,
              source: props.source,
              totalCount: props.members.totalCount,
            });
          }}
        >
          <ExpLabel
            defaultMessage="See All ({count})"
            messageKey="community.members.see-all-count"
            values={{ count: props.members.totalCount }}
          />
        </Link>
      ) : null}
    </div>
  );
};

MemberList.defaultProps = {
  className: '',
  expandable: true,
  expandedListUrl: null,
  fetchPage: () => {},
  heading: null,
  layout: 'list',
  maxItems: null,
  members: {},
  multiRow: false,
  myUuid: null,
};

MemberList.propTypes = {
  className: PropTypes.string,
  expandable: PropTypes.bool,
  expandedListUrl: PropTypes.string,
  fetchPage: PropTypes.func,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  layout: PropTypes.oneOf(['gallery', 'list']),
  maxItems: PropTypes.number,
  members: PropTypes.shape({
    currentPage: PropTypes.number,
    hasMore: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.shape({
      communityMemberSince: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayName: PropTypes.string,
      location: PropTypes.string,
      profileLink: PropTypes.string,
      privateProfile: PropTypes.bool,
      uuid: PropTypes.string,
    })),
    loading: PropTypes.bool,
    pageSize: PropTypes.number,
    stale: PropTypes.bool,
    totalCount: PropTypes.number,
  }),
  multiRow: PropTypes.bool,
  myUuid: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default memo(MemberList);
