import { useFollowedUsers } from 'ERC/following/hooks';

/**
 * Followed Users Loader component
 * - to be used when the followed users needs to be loaded conditionally (i.e. if authenticated)
 */
const FollowedUsersLoader = () => {
  // Ensure the followed users are loaded
  useFollowedUsers();
  return null;
};

export default FollowedUsersLoader;
