// Configured Community Page Types
export const PageTypesByType = {
  MANUFACTURER: {
    label: 'Brand',
    type: 'MANUFACTURER',
  },
  GROUP: {
    label: 'Group',
    type: 'GROUP',
  },
  RETAILER: {
    label: 'Retailer',
    type: 'RETAILER',
  },
  CATEGORY: {
    label: 'Category',
    type: 'CATEGORY',
  },
  PROGRAM: {
    label: 'Program',
    type: 'PROGRAM',
  },
  TEAM_CLASS: {
    label: 'Team Class',
    type: 'TEAM_CLASS',
  },
};

const PageTypes = [
  PageTypesByType.MANUFACTURER,
  PageTypesByType.GROUP,
  PageTypesByType.PROGRAM,
  PageTypesByType.RETAILER,
  PageTypesByType.CATEGORY,
];

export default PageTypes;
