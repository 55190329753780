import React from 'react';
import * as PropTypes from 'prop-types';
import OrgAvatar from 'exp-react-components/src/packages/utilities/components/OrgAvatar';
import Image from 'exp-react-components/src/packages/utilities/components/Image';
import Carousel from 'exp-react-components/src/packages/carousel/Carousel';
import { sendEvent } from '../../utils/analytics';

const TeaserTeamCarousel = ({ items, onClick, title }) => (
  <Carousel
    className="teaser-teams-carousel"
    scroll
    title={title}
  >
    {items.map((team, index) => (
      <a
        className="team-card wrf"
        data-teamid={team.teamId}
        data-teamtype={team.teamType}
        href={team.pageUrl}
        key={team.teamId}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            return;
          }
          // Launch onboarding for the team since we can apply to it here
          e.preventDefault();

          (window.ExpManageGroups || window.ExpOnboarding).show({
            ensureTeam: true,
            teamId: team.teamId,
            complete: (data) => {
              if (window.Exp.neverAffiliated && data?.context.submittedApplication?.teamId) {
                window.location.href = `/user/application/team/${data.context.submittedApplication.teamId}/latest`;
              }
            },
          });

          sendEvent('TEASER_TEAM_CLICK', {
            index,
            teamId: team.teamId,
            teamType: team.teamType,
          });
        }}
        title={team.shortName || team.teamName}
      >
        {team.teamImagePath?.includes('brandAvatar') ? (
          <OrgAvatar
            alt={team.shortName || team.teamName}
            className="org-avatar team-image"
            size={60}
            url={team.teamImagePath}
          />
        ) : (
          <Image
            alt={team.shortName || team.teamName}
            className="team-image"
            src={team.teamImagePath}
          />
        )}
        <p className="team-name">
          {team.shortName || team.teamName}
        </p>
      </a>
    ))}
  </Carousel>
);

TeaserTeamCarousel.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default TeaserTeamCarousel;
