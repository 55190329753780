import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import AwardRibbon from 'ERC/award/AwardRibbon';
import FollowUser from 'ERC/following/FollowUser';
import ExpLabel from 'ERC/localization/ExpLabel';
import UGCModal from 'ERC/ugc/UGCModal';
import { UGC_SOURCE } from 'ERC/ugc/utils/constants';
import OrgAvatar from 'ERC/utilities/components/OrgAvatar';
import Teleporter from 'ERC/utilities/components/Teleporter';
import { useIsMediaMD } from 'ERC/hooks';

import Events from '../../homepage/analytics';
import { handleCommentUGC, handleDeleteUGC, handleEditUGC, handleLikeUGC } from '../redux/expertsActions';
import { sendEvent } from '../../utils/analytics';
import LazyUgcShareModal from '../../common/LazyUgcShareModal';

import './AwardFeedItem.less';

const ModalMode = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

/**
 * Award Expert Feed Item
 */
const AwardFeedItem = (props) => {
  const {
    createdTime,
    org,
    recipient,
    reference,
    referenceId,
    referenceType,
  } = props.item;

  const isMD = useIsMediaMD();
  const [modalMode, setModalMode] = useState(null);
  const [modalParams, setModalParams] = useState({});
  const [ugcPost, setUGCPost] = useState(null);

  const toggleModal = (mode, params) => {
    setModalMode(mode);
    setModalParams(params || {});
  };

  if (!recipient) {
    // Not enough data to render the component correctly
    return null;
  }

  const [firstName] = recipient.displayName?.split(' ', 1) || '';
  return (
    <div
      className={`award-container${props.className ? ` ${props.className}` : ''}`}
      data-award-id={props.item.id}
      data-org-id={props.item.orgId}
      data-reason={props.reason}
      data-recipient={props.item.recipientUserUuid}
      data-reference-id={referenceId}
      data-reference-type={referenceType}
    >
      <div className="award-item">
        <div className="award-details">
          <div className="award-avatars">
            <a className="award-avatar user-avatar" href={recipient.profileLink}>
              <img alt={recipient.displayName} src={recipient.avatar} />
            </a>

            {org?.avatar ? (
              <a className="award-avatar org-avatar" href={org.brandPageUrl}>
                <OrgAvatar
                  alt={org.name}
                  size={isMD ? 80 : 48}
                  url={org.avatar}
                />
              </a>
            ) : null}
          </div>

          <div className="award-info">
            <p className="award-date">
              {moment(new Date(createdTime)).format('MMMM YYYY')}
            </p>
            <h3 className="award-title">
              <div className="award-org-name">{org?.name || 'ExpertVoice'}</div>
              <ExpLabel
                defaultMessage="is recognizing experts."
                messageKey="home.experts.awardTitle"
              />
            </h3>
            <ExpLabel
              className="award-message"
              defaultMessage="Congratulations, {name}!"
              messageKey="home.experts.awardMessage"
              tagName="p"
              values={{ name: firstName }}
            />

            {props.user?.uuid ? (
              <div className="award-actions">
                <button
                  className="link-primary link-view-post"
                  onClick={() => toggleModal(ModalMode.VIEW)}
                  type="button"
                >
                  <ExpLabel
                    defaultMessage="View Post"
                    messageKey="home.experts.viewPost"
                  />
                </button>

                {recipient ? (
                  <FollowUser
                    myUserUuid={props.user.uuid}
                    onClick={({ isFollowing }) => {
                      sendEvent(Events.Search.FOLLOW_USER_TOGGLE, {
                        followed: !isFollowing,
                        source: 'search',
                      });
                    }}
                    privateProfile={recipient.privateProfile}
                    userUuid={recipient.uuid}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        {reference?.image && referenceId && referenceType === 'PHOTO' ? (
          props.user?.uuid ? (
            <a
              className="award-reference"
              href={`/inspired/${referenceId}`}
              onClick={(e) => {
                e.preventDefault();
                toggleModal(ModalMode.VIEW);
              }}
            >
              <AwardRibbon size="large" />
              <img
                alt={reference.description}
                className="award-image"
                src={reference.image}
              />
            </a>
          ) : (
            <div className="award-reference">
              <AwardRibbon size="large" />
              <img
                alt={reference.description}
                className="award-image"
                src={reference.image}
              />
            </div>
          )
        ) : null}
      </div>

      {modalMode ? (
        <Teleporter target="body">
          {[ModalMode.CREATE, ModalMode.EDIT].includes(modalMode) ? (
            <LazyUgcShareModal
              {...modalParams}
              editPost={modalMode === ModalMode.EDIT ? ugcPost : null}
              onCloseClick={() => {
                toggleModal(modalMode === ModalMode.EDIT ? ModalMode.VIEW : null);
              }}
              onPostSubmitted={(data) => {
                if (modalMode === ModalMode.EDIT) {
                  props.onEdit(data);
                }
              }}
              user={props.user}
            />
          ) : (
            <UGCModal
              analyticsSendEvent={sendEvent}
              handleClose={() => {
                toggleModal(null);
              }}
              handleCreateClick={(params) => toggleModal(ModalMode.CREATE, params)}
              handleDelete={() => {
                toggleModal(null);
                props.onDelete(referenceId);
              }}
              handleEdit={(post) => {
                setUGCPost(post);
                toggleModal(ModalMode.EDIT);
              }}
              onComment={props.onComment}
              onLikeToggle={props.onLikeToggle}
              source={props.source}
              ugcId={referenceId}
              user={props.user}
            />
          )}
        </Teleporter>
      ) : null}
    </div>
  );
};

AwardFeedItem.defaultProps = {
  className: null,
  reason: null,
  user: null,
};

AwardFeedItem.propTypes = {
  className: PropTypes.string,
  onComment: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onLikeToggle: PropTypes.func.isRequired,
  item: PropTypes.shape({
    createdTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    id: PropTypes.number.isRequired,
    org: PropTypes.shape({
      avatar: PropTypes.string,
      brandPageUrl: PropTypes.string,
      name: PropTypes.string,
      orgImageUrl: PropTypes.string,
      orgKey: PropTypes.string,
    }),
    orgId: PropTypes.number,
    recipient: PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      privateProfile: PropTypes.bool.isRequired,
      profileLink: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
    }),
    recipientUserUuid: PropTypes.string.isRequired,
    reference: PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.string,
    }),
    referenceId: PropTypes.string,
    referenceType: PropTypes.string,
  }).isRequired,
  reason: PropTypes.string,
  source: PropTypes.oneOf(Object.values(UGC_SOURCE)),
  user: PropTypes.shape({
    uuid: PropTypes.string,
  }),
};

export default connect(() => ({}), (dispatch) => bindActionCreators({
  onComment: handleCommentUGC,
  onDelete: handleDeleteUGC,
  onEdit: handleEditUGC,
  onLikeToggle: handleLikeUGC,
}, dispatch))(AwardFeedItem);
