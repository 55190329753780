import React from 'react';
import PropTypes from 'prop-types';

const ActivityCta = (props) => (
  <div
    className={`campaign-summary-container campaign-summary-cta${
      props.type ? ` cta-${props.type}` : ''}${
      props.className ? ` ${props.className}` : ''}`}
    data-cta-type={props.type}
  >
    {props.details ? (
      <div className="cta-details">{props.details}</div>
    ) : props.title || props.message ? (
      <div className="cta-details">
        {props.icon ? (
          <div className="cta-icon">{props.icon}</div>
        ) : null}

        <div className="cta-text">
          {props.title ? (
            <h3 className="cta-title">{props.title}</h3>
          ) : null}

          {props.message ? (
            <p className="cta-message">{props.message}</p>
          ) : null}
        </div>
      </div>
    ) : null}

    {props.children ? (
      <div className="cta-actions">
        {props.children}
      </div>
    ) : null}
  </div>
);

ActivityCta.defaultProps = {
  children: null,
  className: null,
  details: null,
  dueDate: null,
  icon: null,
  message: null,
  title: null,
  type: null,
};

ActivityCta.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  details: PropTypes.node,
  icon: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dueDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.string,
};

export default ActivityCta;
