import React from 'react';
import PropTypes from 'prop-types';

import ExpLabel from 'ERC/localization/ExpLabel';

import './LoadingError.less';

const LoadingError = (props) => (
  <div
    className={`page-error loading-error${
      props.small ? ' small' : ' large'}${
      props.className ? ` ${props.className}` : ''}`}
  >
    <div className="error-icon">
      {props.icon || ( // TODO xlarge size
        <i className="exp-ux-info exp-ux-large" />
      )}
    </div>
    <div className="error-details">
      <h3 className="error-title">
        {props.title || (
          <ExpLabel
            defaultMessage="Oops! It looks like something went wrong."
            messageKey="home.feed.error.title"
          />
        )}
      </h3>
      <p className="error-message">
        {props.message || (
          <ExpLabel
            defaultMessage="An error occurred loading your opportunities."
            messageKey="home.feed.error.bucket-loading"
          />
        )}
      </p>
      {props.onRetry ? (
        <button
          className="try-again-link link-primary"
          onClick={() => props.onRetry()}
          type="button"
        >
          {props.retry || (
            <ExpLabel
              defaultMessage="Reload"
              messageKey="home.feed.reload"
            />
          )}
        </button>
      ) : null}
    </div>
  </div>
);

LoadingError.defaultProps = {
  className: undefined,
  message: undefined,
  onRetry: undefined,
  small: false,
  retry: undefined,
  title: undefined,
};

LoadingError.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onRetry: PropTypes.func,
  retry: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  small: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default LoadingError;
