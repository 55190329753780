import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ExpLabel } from 'ERC/localization';
import { sendEvent } from '../../utils/analytics';
import './blockerBreadcrumb.less';
/*
 Back to qualify breadcrumb for users in the never unaffiliated state
 */
export const AnalyticEvents = {
  ACQUISITION_PAGE_ONB_BACK_CLICK: 'ACQUISITION_PAGE_ONB_BACK_CLICK',
};
const DESTINATION = '/user/qualify';

const BlockerBreadcrumb = (({ id, pageType }) => {
  const neverAffiliated = useRef(window.Exp?.neverAffiliated);
  if (!neverAffiliated.current) {
    return null;
  }
  return (
    <section className="blocker-breadcrumb">
      <div className="blocker-breadcrumb-content">
        <a
          className="back-btn link-primary"
          href={DESTINATION}
          onClick={(e) => {
            e.preventDefault();
            sendEvent(AnalyticEvents.ACQUISITION_PAGE_ONB_BACK_CLICK, { id, pageType });
            window.history.back();
            // fallback to hardcoded if back doesn't respond
            window.location = DESTINATION;
          }}
        >
          <i className="exp-ux-small exp-ux-arrow" />
          <ExpLabel
            defaultMessage="Back"
            messageKey="home.feed.back"
          />
        </a>
      </div>
    </section>
  );
});

BlockerBreadcrumb.propTypes = {
  id: PropTypes.string || PropTypes.number,
  pageType: PropTypes.string,
};

export default BlockerBreadcrumb;
