import PropTypes from 'prop-types';

import {
  ACTIVITIES,
  RECRUITMENT_ACTIVITY_STATUS,
  SHIPPING_ACTIVITY_STATUS,
  GENERIC_ACTIVITY_STATUS,
} from './constants';

const engagementShape = {
  asOf: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  complete: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export const EngagementPropType = PropTypes.shape(engagementShape);

const GenericEngagementPropType = PropTypes.shape({
  ...engagementShape,
  dueDate: PropTypes.string,
});

export const TagPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  publishedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sortOrder: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  tagId: PropTypes.number.isRequired,
  tagType: PropTypes.string,
  type: PropTypes.string,
});

export const ModulePropType = PropTypes.shape({
  attempted: PropTypes.bool,
  certified: PropTypes.bool,
  completed: PropTypes.bool,
  completionType: PropTypes.string,
  description: PropTypes.string,
  edugame: PropTypes.bool,
  eduGameAttempts: PropTypes.number,
  eduGameCompletes: PropTypes.number,
  first: PropTypes.bool,
  formattedDuration: PropTypes.string,
  imageUrl: PropTypes.string,
  lessonIndex: PropTypes.number,
  link: PropTypes.string,
  locked: PropTypes.bool,
  moduleId: PropTypes.number,
  percentCorrect: PropTypes.number,
  status: PropTypes.string,
  title: PropTypes.string,
});

export const CoursePropType = PropTypes.shape({
  adminDescription: PropTypes.string,
  completed: PropTypes.bool,
  completionThreshold: PropTypes.number,
  courseId: PropTypes.number,
  description: PropTypes.string,
  modules: PropTypes.arrayOf(ModulePropType).isRequired,
  name: PropTypes.string,
  numModulesCompleted: PropTypes.number,
  originatingTags: PropTypes.arrayOf(TagPropType),
  prerequisites: PropTypes.arrayOf(PropTypes.shape({
    completed: PropTypes.bool,
    courseId: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
  })),
  sequenceModules: PropTypes.bool,
});

export const ProductPropType = PropTypes.shape({
  currencyCode: PropTypes.string,
  discount: PropTypes.number,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  priceString: PropTypes.string,
  productCode: PropTypes.string,
  productLink: PropTypes.string,
  retailPrice: PropTypes.number,
  retailPriceString: PropTypes.string,
});

export const StorePropType = PropTypes.shape({
  actionLabel: PropTypes.string,
  blockActionable: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  linkable: PropTypes.bool,
  locked: PropTypes.bool,
  name: PropTypes.string,
  numProducts: PropTypes.number,
  originatingTags: PropTypes.arrayOf(TagPropType),
  products: PropTypes.shape({
    resultItems: PropTypes.arrayOf(ProductPropType),
    totalResults: PropTypes.number,
  }),
  punchout: PropTypes.bool,
  storeLink: PropTypes.string,
});

export const ChallengeActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    challengeId: PropTypes.number.isRequired,
    descriptionJson: PropTypes.string,
    dueDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    orgId: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: EngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.CHALLENGE]).isRequired,
});

export const RecruitmentActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    descriptionJson: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(RECRUITMENT_ACTIVITY_STATUS)).isRequired,
    teamApplicationFormId: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: EngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.RECRUITMENT]).isRequired,
});

export const ShippingActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    metaDataJson: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(SHIPPING_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: PropTypes.shape({
    ...engagementShape,
    expectedShipDate: PropTypes.string,
    instructions: PropTypes.string,
    productReceivedDate: PropTypes.string,
    shippingStatusUrl: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf([ACTIVITIES.SHIPPING]).isRequired,
});

export const SocialShareActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    socialShareTags: PropTypes.arrayOf(PropTypes.shape({
      tagText: PropTypes.string.isRequired,
      tagType: PropTypes.string.isRequired,
    })).isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: GenericEngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.SOCIAL_SHARE]).isRequired,
});

export const ContentUploadActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: GenericEngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.CONTENT_UPLOAD]).isRequired,
});

export const ClickActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: GenericEngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.CLICK]).isRequired,
});

export const StoreActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    stores: PropTypes.arrayOf(StorePropType),
  }).isRequired,
  engagement: EngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.STORE]).isRequired,
});

export const SurveyResponsePropType = PropTypes.shape({
  answers: PropTypes.arrayOf(PropTypes.shape({
    optional: PropTypes.bool,
    questionText: PropTypes.string,
    questionType: PropTypes.string,
    surveyResponseAnswerId: PropTypes.number.isRequired,
    xdsAssetSuid: PropTypes.string,
  })),
  campaignId: PropTypes.number,
  createdTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moderationMessage: PropTypes.string,
  status: PropTypes.string,
  surveyActivityId: PropTypes.number,
  surveyResponseId: PropTypes.number.isRequired,
});

export const SurveyActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    dueDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    orgId: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
    targetNumberOfSubmissions: PropTypes.number,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: PropTypes.shape({
    ...engagementShape,
    engagements: PropTypes.arrayOf(PropTypes.shape({
      createdTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })),
  }).isRequired,
  leaderboard: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number.isRequired,
    latestResponseTime: PropTypes.string,
    user: PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      expertProfileVisibility: PropTypes.string,
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  })),
  responses: PropTypes.shape({
    content: PropTypes.arrayOf(SurveyResponsePropType).isRequired,
    page: PropTypes.shape({
      number: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      totalElements: PropTypes.number.isRequired,
      totalPages: PropTypes.number.isRequired,
    }).isRequired,
  }),
  statusCounts: PropTypes.shape({
    APPROVED: PropTypes.number,
    PENDING: PropTypes.number,
    REJECTED: PropTypes.number,
  }),
  type: PropTypes.oneOf([ACTIVITIES.SURVEY]).isRequired,
});

export const TrainingActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    courses: PropTypes.arrayOf(CoursePropType).isRequired,
    dueDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  engagement: EngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.TRAINING]).isRequired,
});

export const WaitActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    descriptionJson: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: GenericEngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.WAIT]).isRequired,
});

export const RecommendationActivityPropType = PropTypes.shape({
  activity: PropTypes.shape({
    descriptionJson: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    orgId: PropTypes.number.isRequired,
    productCode: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(GENERIC_ACTIVITY_STATUS)).isRequired,
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  engagement: GenericEngagementPropType.isRequired,
  type: PropTypes.oneOf([ACTIVITIES.RECOMMENDATION]).isRequired,
});

export const ActivitiesPropType = PropTypes.oneOfType([
  ChallengeActivityPropType,
  ClickActivityPropType,
  ContentUploadActivityPropType,
  RecruitmentActivityPropType,
  RecommendationActivityPropType,
  ShippingActivityPropType,
  SocialShareActivityPropType,
  StoreActivityPropType,
  SurveyActivityPropType,
  TrainingActivityPropType,
  WaitActivityPropType,
]);

export const CategoryType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const CertificationPropType = PropTypes.shape({
  certifiable: PropTypes.bool,
  certified: PropTypes.bool,
});

export const LearnDataPropType = PropTypes.shape({
  certifiedModules: PropTypes.number,
  completedModules: PropTypes.number,
  nextModuleLink: PropTypes.string,
  totalCourses: PropTypes.number,
  totalModules: PropTypes.number,
});

export const BrandPropType = PropTypes.shape({
  orgId: PropTypes.number,
  name: PropTypes.string,
  orgKey: PropTypes.string,
  avatarAssetUuid: PropTypes.string,
  modifiedTime: PropTypes.string,
  orgImageUrl: PropTypes.string,
  brandPageUrl: PropTypes.string,
  communityPageUrl: PropTypes.string,
  communityPageKey: PropTypes.string,
  isBlocked: PropTypes.bool,
  isLiked: PropTypes.bool,
  targeted: PropTypes.bool,
  orgClientStatus: PropTypes.number,
  categories: PropTypes.array,
});

export const CampaignPropType = PropTypes.shape({
  activities: PropTypes.arrayOf(ActivitiesPropType),
  assetAnchorPosition: PropTypes.string,
  campaignId: PropTypes.number.isRequired,
  certification: CertificationPropType,
  communityPageLinkUrl: PropTypes.string,
  courses: PropTypes.arrayOf(CoursePropType),
  description: PropTypes.string,
  headline: PropTypes.string,
  learnData: LearnDataPropType,
  modules: PropTypes.arrayOf(ModulePropType),
  optionalLearning: PropTypes.bool,
  orgAvatarImageUrl: PropTypes.string,
  orgId: PropTypes.number,
  orgName: PropTypes.string,
  priority: PropTypes.number,
  reportingType: PropTypes.string,
  storeData: StorePropType,
  targetingType: PropTypes.string,
});
