import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Carousel from 'ERC/carousel/Carousel';
import ExpLabel from 'ERC/localization/ExpLabel';
import OrgAvatar from 'ERC/utilities/components/OrgAvatar';
import { UGC_SOURCE } from 'ERC/ugc/utils/constants';

import { fetchBrandAwards } from '../../../redux/actions';
import AwardFeedItem from '../../../../expertsTab/components/AwardFeedItem';
import ErrorBoundary from '../../../../common/ErrorBoundary';
import LazyUgcShareModal from '../../../../common/LazyUgcShareModal';

import './brandAwards.less';

const POST_PHOTO_HASH = '#post-photo';

/**
 * Brand Awards
 */
export const BrandAwards = (props) => {
  const shouldTriggerPhoto = window.location.hash === POST_PHOTO_HASH;
  if (shouldTriggerPhoto) {
    window.history?.replaceState?.(null, null, window.location.href
      .substring(0, window.location.href.indexOf('#')));
  }

  const [showUgcModal, setShowUgcModal] = useState(props.isAuthenticated && shouldTriggerPhoto);

  useEffect(() => {
    // Load the awards if they have not yet been loaded
    if (!props.awards) {
      props.fetchBrandAwards({
        acquisition: props.acquisition,
        size: props.maxToShow,
      });
    }
  }, [props]);

  if (!props.awards?.length && !showUgcModal) {
    // Show nothing if there are no rewards
    return null;
  }

  const handleShareClick = () => {
    if (props.isAuthenticated) {
      setShowUgcModal(true);
    } else if (window.ExpOnboarding?.signUp) {
      window.ExpOnboarding.signUp({
        complete: () => {
          // Add the #post-photo hash and reload the page to launch the UGC Share Modal
          window.location.hash = POST_PHOTO_HASH;
          window.location.reload();
        },
      });
    }
  };

  return (
    <ErrorBoundary>
      {props.awards?.length ? (
        <div className="brand-awards">
          <div className="community-container">
            <ExpLabel
              className="content-section"
              defaultMessage="{orgName} Excellence Awards"
              messageKey="community.awards.title"
              values={{ orgName: props.org.name }}
            />

            <div className="awards-container">
              <Carousel className="awards-carousel">
                {props.awards.map((award) => (
                  <AwardFeedItem
                    className="award-carousel-item"
                    item={award}
                    key={award.id}
                    source={UGC_SOURCE.SOURCE_BRAND_PAGE}
                    user={props.user}
                  />
                ))}
              </Carousel>

              <div className="award-prompt">
                <h3 className="award-prompt-heading">
                  <ExpLabel
                    defaultMessage="Have an award-worthy photo with a {orgName} product?"
                    messageKey="community.awards.prompt.heading"
                    values={{ orgName: props.org.name }}
                  />
                </h3>

                <div className="award-prompt-graphic">
                  {props.avatar ? (
                    <div className="org-avatar">
                      <OrgAvatar
                        alt={props.org.name}
                        size={80}
                        url={props.avatar}
                      />
                    </div>
                  ) : null}

                  <button
                    className="award-prompt-dropzone"
                    onClick={() => handleShareClick()}
                    type="button"
                  >
                    <i className="exp-ux-large exp-ux-photo" />
                  </button>
                </div>

                <button
                  className="btn-outline btn-share"
                  onClick={() => handleShareClick()}
                  type="button"
                >
                  <ExpLabel
                    defaultMessage="Share My Experience"
                    messageKey="community.awards.prompt.button"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {props.user && showUgcModal ? (
        <LazyUgcShareModal
          brandTags={[{
            orgAvatar: props.org.avatar,
            orgKey: props.org.orgKey,
            orgName: props.org.name,
          }]}
          onCloseClick={() => setShowUgcModal(false)}
          user={props.user}
        />
      ) : null}
    </ErrorBoundary>
  );
};

BrandAwards.defaultProps = {
  avatar: null,
  maxToShow: 3,
};

BrandAwards.propTypes = {
  acquisition: PropTypes.bool,
  avatar: PropTypes.string,
  awards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    recipient: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  })),
  fetchBrandAwards: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  maxToShow: PropTypes.number,
  org: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    orgKey: PropTypes.string.isRequired,
  }),
  user: PropTypes.object,
};

export default connect((state) => ({
  avatar: state.community.avatar,
  awards: state.community.awards,
  isAuthenticated: state.community.isAuthenticated,
  org: state.community.org,
  user: state.community.user,
}), (dispatch) => bindActionCreators({
  fetchBrandAwards,
}, dispatch))(BrandAwards);
