/**
 * Campaign Home Analytic Events
 */
const CampaignEvents = {
  CERTIFICATION: {
    CLICK: 'CAMPAIGNHOME_CERTIFICATION_CLICK',
  },
  COURSE: {
    CLICK: 'CAMPAIGNHOME_COURSE_CLICK',
    PREREQUISITE_CLICK: 'CAMPAIGNHOME_COURSEPREREQUISITE_CLICK',
  },
  LEARN_PROGRESS: {
    CLICK: 'CAMPAIGNHOME_LEARNPROGRESS_CLICK',
  },
  MODULE: {
    CLICK: 'CAMPAIGNHOME_MODULE_CARD_CLICK',
  },
  PREVIEW: {
    TAG_SELECT: 'CAMPAIGNHOME_PREVIEW_TAG_SELECT',
    TAG_SUBMIT: 'CAMPAIGNHOME_PREVIEW_TAG_SUBMIT',
  },
  PRODUCT: {
    CLICK: 'CAMPAIGNHOME_PRODUCT_CARD_CLICK',
  },
  REPORT_PROBLEM: {
    CLICK: 'CAMPAIGNHOME_REPORT_PROBLEM',
  },
  STORE: {
    CLICK: 'CAMPAIGNHOME_STORE_CARD_CLICK',
    LOAD_ERROR: 'CAMPAIGNHOME_STORE_LOAD_ERROR',
  },
  SUMMARY: {
    CLICK: 'CAMPAIGNHOME_SUMMARY_CLICK',
  },
  SURVEY: {
    CLOSE: 'CAMPAIGNHOME_SURVEY_CLOSE',
    COMPLETE: 'CAMPAIGNHOME_SURVEY_COMPLETE',
    RESPONSE_CLOSE: 'CAMPAIGNHOME_SURVEYRESPONSE_CLOSE',
    RESPONSE_VIEW: 'CAMPAIGNHOME_SURVEYRESPONSE_VIEW',
  },
  VIEW: 'CAMPAIGNHOME_VIEW',
};

/**
 * Multi-Brand Campaign Home Analytic Events
 */
const MBCampaignEvents = {
  BRAND: {
    CLICK: 'MULTIBRANDCAMPAIGNHOME_BRAND_CLICK',
  },
  DETAILS: {
    VIEW: 'MULTIBRANDCAMPAIGNDETAILS_VIEW',
  },
  VIEW: 'MULTIBRANDCAMPAIGNHOME_VIEW',
};

/**
 * PLC / Recruited Activities Campaign Home Analytic Events
 */
const PLCEvents = {
  MODAL: {
    CLOSE: 'PLCHOME_MODAL_CLOSE',
  },
  STATES: {
    click: 'online-review',
    complete: 'completed',
    content_upload: 'content-upload',
    recommendation: 'recommendation',
    recruitment: 'recruitment',
    shipping: 'shipping-instructions',
    social_share: 'instagram-social-share',
    wait: 'wait',
    undefined: 'unknown', // should never happen
  },
  STEPS: {
    RECRUITMENT_APPLY_CLICK: 'PLCHOME_SECONDARY_CLICK',
    REPORT_PROBLEM_CLICK: 'PLCHOME_REPORT_PROBLEM',
    TASK_CLICK: 'PLCHOME_TASK_CLICK',
    TASK_SUBMIT: 'PLCHOME_TASK_SUBMIT',
  },
  SUMMARY: {
    CLICK: 'PLCHOME_SUMMARY_CLICK',
  },
  VIEW: 'PLCHOME_VIEW',
};

export const HomeNavEvents = {
  MEGA_MENU: {
    NAVIGATION_BACK_CLICK: 'NAVIGATION_BACK_CLICK',
    NAVIGATION_CLICK: 'NAVIGATION_CLICK',
  },
};

export {
  CampaignEvents,
  MBCampaignEvents,
  PLCEvents,
};
