import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import xhr from '../../utils/xhr';

export const FEED_PAGE_SIZE = 18;
export const FEED_SOURCE_FOLLOW = 'FOLLOW';
export const FEED_SOURCE_SUGGESTED = 'SUGGESTED';
export const FEED_SOURCE_FEATURED = 'FEATURED';
export const VIDEO_POOL_SIZE = 10;

export const ExpertsFeedItemType = {
  AWARD: 'AWARD',
  POLL: 'POLL',
  PROMPT: 'PROMPT',
  QUESTION: 'QUESTION',
  RECOMMENDATION: 'RECOMMENDATION',
  RECOMMENDATION_VIDEO: 'RECOMMENDATION_VIDEO',
  UGC: 'UGC',
};

export const SocialismType = {
  POLL: 'POLL',
  QUESTION: 'QUESTION',
  RECOMMENDATION: 'REVIEW',
};

const fetchFeedData = (type, config) => {
  const {
    categoryIds,
    initialPageSize,
    itemMinTime,
    nextPageFilters,
    source,
  } = config;

  const pageSize = initialPageSize || FEED_PAGE_SIZE;

  const itemTypesMaxTime = [type].flat().reduce((map, item) => ({
    ...map,
    [item]: null,
  }), {});

  const itemTypesMinTime = itemMinTime ? { [type]: itemMinTime } : undefined;

  return xhr('/xapi/user-content/ext/3.0/expertsTab', 'post', (nextPageFilters || {
    categoryIds,
    itemTypesMaxTime,
    itemTypesMinTime,
    pageSize,
    source,
  }));
};

export const fetchCategories = createAsyncThunk('categories/list/fetch', async (userUuid) => {
  const response = await xhr(`/xapi/profile/pub/1.0/category-picker/users/${userUuid}`, 'get');
  return response.data;
});

export const fetchFavorites = createAsyncThunk('categories/favorites/fetch', async (userUuid) => {
  const response = await xhr(`/xapi/profile/pub/1.0/category-picker/favorites/${userUuid}`, 'get');
  return response.data;
});

export const removePromptWidget = createAsyncThunk(
  'prompts/dismiss',
  async ({ card, method = 'post' }) => {
    const response = await xhr(card.dismissUrl, method);
    return response.data;
  },
);

export const fetchAggregateFeed = createAsyncThunk('expertsFeed/followee/fetch', async (config) => {
  const response = await fetchFeedData([
    ExpertsFeedItemType.AWARD,
    ExpertsFeedItemType.RECOMMENDATION,
    ExpertsFeedItemType.UGC,
  ], {
    ...config,
    categoryIds: [],
    sort: 'NEW',
    source: FEED_SOURCE_FOLLOW,
  });
  return response.data;
});

export const fetchPrompts = createAsyncThunk('prompts/fetch', async () => {
  const response = await xhr('/xapi/profile/ext/1.0/prompts/user', 'get');
  return response.data;
});

export const fetchAwardFeed = createAsyncThunk('expertsFeed/award/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.AWARD, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_FEATURED,
  });

  return response.data;
});

export const fetchPollFeed = createAsyncThunk('expertsFeed/poll/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.POLL, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_FEATURED,
  });

  return response.data;
});

export const fetchRecFeed = createAsyncThunk('expertsFeed/recs/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.RECOMMENDATION, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_SUGGESTED,
  });
  return response.data;
});

export const fetchQuestionFeed = createAsyncThunk('expertsFeed/questions/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.QUESTION, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_FEATURED,
  });
  return response.data;
});

export const fetchRatingSummary = createAsyncThunk('recommendation/summary', async ({ orgId, productCode }) => {
  const response = await xhr(`/xapi/voice/pub/1.0/review/summary?entityOwnerId=${orgId}&entityId=${productCode}&entityAlternateIds=${productCode}`, 'get');
  return response.data;
});

export const fetchRecommendation = createAsyncThunk('recommendation/fetch', async (id) => {
  const response = await xhr(`/xapi/voice/ext/1.0/review/${id}/full`, 'get');
  return response.data;
});

export const fetchSocialism = createAsyncThunk('socialism/fetch', async (id) => {
  const response = await xhr(`/xapi/voice/pub/1.0/socialism/${id}/enriched`, 'get');
  return response.data;
});

export const fetchUGC = createAsyncThunk('ugc/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.UGC, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_FEATURED,
  });
  return response.data;
});

export const fetchVideoRecs = createAsyncThunk('expertsFeed/videoRecs/fetch', async (config) => {
  const response = await fetchFeedData(ExpertsFeedItemType.RECOMMENDATION_VIDEO, {
    ...config,
    source: config.followMode ? FEED_SOURCE_FOLLOW : FEED_SOURCE_SUGGESTED,
  });
  return response.data;
});

export const fetchVotes = createAsyncThunk('socialism/votes/fetch', async () => {
  const response = await xhr('/xapi/voice/ext/1.0/review/votes', 'get');
  return response.data;
});

export const refreshPolls = createAction('expertsFeed/polls/refresh');
export const resetExpertsFeed = createAction('expertsFeed/reset');

export const fetchExpertsFeed = createAsyncThunk('expertsFeed/fetch', async (params, { dispatch, getState }) => {
  // Reset everything
  dispatch(resetExpertsFeed());

  const {
    pageSizes: {
      [ExpertsFeedItemType.AWARD]: awardPageSize,
      [ExpertsFeedItemType.RECOMMENDATION]: recommendationsPageSize,
      [ExpertsFeedItemType.UGC]: ugcPageSize,
    },
    ...config
  } = params;

  // only grab video recs that have been created in the past 3 months
  const aFewMonthsAgo = new Date();
  aFewMonthsAgo.setDate(aFewMonthsAgo.getDate() - 90);

  return Promise.allSettled([
    fetchAwardFeed({
      ...config,
      initialPageSize: awardPageSize,
    })(dispatch, getState),
    fetchRecFeed({
      ...config,
      initialPageSize: recommendationsPageSize,
    })(dispatch, getState),
    fetchUGC({
      ...config,
      initialPageSize: ugcPageSize,
    })(dispatch, getState),
    fetchVideoRecs({
      ...config,
      initialPageSize: VIDEO_POOL_SIZE,
      itemMinTime: aFewMonthsAgo,
    })(dispatch, getState),
  ]);
});

export const submitPoll = createAsyncThunk('socialism/poll/submit', async ({
  id: optionId,
  parentRecordId: pollId,
}) => {
  const response = await xhr('/xapi/voice/ext/1.0/poll', 'post', { optionId, pollId });
  return response.data;
});

export const handleCommentUGC = createAction('ugc/comment/submit');
export const handleDeleteUGC = createAction('ugc/post/delete');
export const handleEditUGC = createAction('ugc/post/edit');
export const handleLikeUGC = createAction('ugc/like/submit');
export const handlePostUGC = createAction('ugc/post/submit');
export const removeRecommendation = createAction('socialism/remove');
export const removeUgcBrandTag = createAction('ugc/brandTag/remove');
export const updateComments = createAction('socialism/comments/update');
