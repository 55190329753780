import React from 'react';
import PropTypes from 'prop-types';

import FollowBrand from 'ERC/following/FollowBrand';
import OrgAvatar from 'ERC/utilities/components/OrgAvatar';

import BrandAccessBanner from '../BrandAccessBanner';
import { sendEvent } from '../../utils/analytics';

import './CardSizes.less';
import './BrandCard.less';

const CATEGORY_LIMIT = 3;

const BrandCard = (props) => (
  <div
    className={`card-wrapper brand-card card-${props.size}${props.expanded ? ' expanded' : ''}${props.accessIndicator ? ' access-indicator' : ''}`}
    data-orgid={props.brand.orgId}
    data-orgkey={props.brand.orgKey}
    data-pending={props.brand.pending}
    data-targeted={props.brand.targeted}
  >
    <div
      className="card"
      title={props.brand.name}
    >
      <a
        href={props.brand.navigationUrl || props.brand.brandPageUrl}
        onClick={props.onClick}
      >
        <div className="card-images">
          <OrgAvatar
            alt={props.brand.name}
            className="card-image org-avatar"
            url={props.brand.orgImageUrl}
          />

          {props.accessIndicator ? (
            <BrandAccessBanner
              orgId={props.brand.orgId}
              pending={props.brand.pending}
              targeted={props.brand.targeted}
            />
          ) : null}
        </div>

        <div className="card-text">
          {props.hideName ? null : (
            <span className="brand-name">
              {props.brand.name}
            </span>
          )}

          {props.expanded && props.brand.categories.length ? (
            <span className="brand-categories">
              {props.brand.categories
                .slice(0, CATEGORY_LIMIT)
                .map((category) => category.name)
                .join(', ')}
            </span>
          ) : null}
        </div>
      </a>

      {props.expanded ? (
        <FollowBrand
          analyticsSendEvent={sendEvent}
          orgId={props.brand.orgId}
          orgName={props.brand.name}
          noIcon
          noMenu
          source={props.source}
          type="link-color"
        />
      ) : null}
    </div>
  </div>
);

BrandCard.defaultProps = {
  accessIndicator: false,
  expanded: false,
  hideName: false,
  onClick: () => {},
  size: 'xs',
};

BrandCard.propTypes = {
  accessIndicator: PropTypes.bool,
  brand: PropTypes.shape({
    brandPageUrl: PropTypes.string.isRequired,
    categories: PropTypes.array,
    name: PropTypes.string,
    navigationUrl: PropTypes.string,
    orgClientStatus: PropTypes.number,
    orgId: PropTypes.number,
    orgImageUrl: PropTypes.string,
    orgKey: PropTypes.string,
    pending: PropTypes.bool,
    targeted: PropTypes.bool,
  }).isRequired,
  expanded: PropTypes.bool,
  hideName: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  source: PropTypes.string,
};

export default BrandCard;
