import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SurveyActivityPropType } from '../../../config/propTypes';
import { REPORTING_TYPES } from '../../../config/constants';
import ReportActivityCta from './reportActivityCta';

/**
 * Survey Activity CTA
 */
const SurveyCta = (props) => {
  const campaign = useSelector((state) => state.campaign.campaign);
  if (campaign.reportingType === REPORTING_TYPES.ACTIVITY) {
    // Activity campaigns use the Report Activity CTA
    return (
      <ReportActivityCta {...props} />
    );
  }

  // No fallback for an inline survey activity
  return null;
};

SurveyCta.defaultProps = {
  className: null,
  onCtaClick: () => {},
};

SurveyCta.propTypes = {
  activity: SurveyActivityPropType.isRequired,
  className: PropTypes.string,
  onCtaClick: PropTypes.func,
};

export default SurveyCta;
