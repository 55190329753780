import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'ERC/loadingSpinner/LoadingSpinner';
import { SurveyActivityPropType } from '../../../../config/propTypes';

const ReportActivityModal = React.lazy(() => import(/* webpackChunkName: "campaign-reportActivityModal" */ './ReportActivityModal'));

/**
 * Lazy Report Activity Survey Modal
 */
const ReportActivityModalLazy = ({ fallback, ...props }) => (
  <Suspense fallback={fallback || (<LoadingSpinner />)}>
    <ReportActivityModal {...props} />
  </Suspense>
);

ReportActivityModalLazy.defaultProps = {
  fallback: null,
};

ReportActivityModalLazy.propTypes = {
  fallback: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  survey: SurveyActivityPropType.isRequired,
};

export default ReportActivityModalLazy;
