import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isBrandBlocked } from 'ERC/following/slice';
import ExpLabel from 'ERC/localization/ExpLabel';

import './BrandAccessBanner.less';

/**
 * Brand Access Banner
 */
const BrandAccessBanner = (props) => {
  const blocked = useSelector((state) => isBrandBlocked(state, props.orgId));
  if (!blocked && props.targeted) {
    // Nothing to show
    return null;
  }

  return (
    <div className={`brand-access-banner${props.className ? ` ${props.className}` : ''}`}>
      {blocked ? (
        <ExpLabel
          defaultMessage="Blocked"
          messageKey="home.brands.access-banner.blocked"
        />
      ) : props.pending ? (
        <ExpLabel
          defaultMessage="Pending"
          messageKey="home.brands.access-banner.pending"
        />
      ) : (
        <ExpLabel
          defaultMessage="No Rewards"
          messageKey="home.brands.access-banner.noRewards"
        />
      )}
    </div>
  );
};

BrandAccessBanner.defaultProps = {
  className: undefined,
  pending: false,
  targeted: false,
};

BrandAccessBanner.propTypes = {
  className: PropTypes.string,
  orgId: PropTypes.number.isRequired,
  pending: PropTypes.bool,
  targeted: PropTypes.bool,
};

export default BrandAccessBanner;
