import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpLabel from 'ERC/localization/ExpLabel';
import { useIsMediaLG } from 'ERC/hooks';

import { SurveyActivityPropType } from '../../../config/propTypes';
import { formatShortDate } from '../../../../utils/utils';
import ActivityCta from '../activityCta';
import ReportActivityModal from './modal/ReportActivityModalLazy';
import ReportActivityStatus from './reportActivityStatus';

const ReportActivityCta = (props) => {
  const {
    activity: { dueDate, targetNumberOfSubmissions: target },
    engagement: { complete },
  } = props.activity;

  const isLG = useIsMediaLG();
  const [showSurvey, setShowSurvey] = useState(false);

  return (
    <>
      <ActivityCta
        className={[props.className || '', (isLG ? '' : 'inline')].join(' ').trim()}
        details={isLG ? null : (
          <ReportActivityStatus
            activity={props.activity}
            showAvatar
          />
        )}
        message={!isLG ? null : complete ? (
          <ExpLabel
            defaultMessage="You have completed the minimum activities, but you can add more."
            messageKey="campaign.activity.description.complete"
            namespace="messages/campaignMessages"
          />
        ) : dueDate && target > 1 ? (
          <ExpLabel
            defaultMessage="Submit at least {target} activity report by {date}."
            messageKey="campaign.activity.description.target-due-by"
            namespace="messages/campaignMessages"
            values={{ date: formatShortDate(dueDate), target }}
          />
        ) : dueDate && target ? (
          <ExpLabel
            defaultMessage="Submit at least {target} activity reports by {date}."
            messageKey="campaign.activity.description.targets-due-by"
            namespace="messages/campaignMessages"
            values={{ date: formatShortDate(dueDate), target }}
          />
        ) : dueDate ? (
          <ExpLabel
            defaultMessage="Report your activity by {date}."
            messageKey="campaign.activity.description.due-by"
            namespace="messages/campaignMessages"
            values={{ date: formatShortDate(dueDate) }}
          />
        ) : target > 1 ? (
          <ExpLabel
            defaultMessage="Submit at least {target} activity reports."
            messageKey="campaign.activity.description.targets"
            namespace="messages/campaignMessages"
            values={{ target }}
          />
        ) : target ? (
          <ExpLabel
            defaultMessage="Submit at least {target} activity report."
            messageKey="campaign.activity.description.target"
            namespace="messages/campaignMessages"
            values={{ target }}
          />
        ) : null}
        title={!isLG ? null : complete ? (
          <ExpLabel
            defaultMessage="Thank you for participating."
            messageKey="campaign.activity.title.complete"
            namespace="messages/campaignMessages"
          />
        ) : (
          <ExpLabel
            defaultMessage="Report here after completing this activity."
            messageKey="campaign.activity.title.incomplete"
            namespace="messages/campaignMessages"
          />
        )}
        type={props.activity.type}
      >
        {props.activity.engagement.enabled ? (
          <button
            className="cta-button btn-primary"
            onClick={() => {
              setShowSurvey(true);
              props.onCtaClick();
            }}
            type="button"
          >
            <ExpLabel
              defaultMessage="Report your activity"
              messageKey="campaign.activity.report-activity"
              namespace="messages/campaignMessages"
            />
          </button>
        ) : null}
      </ActivityCta>

      {showSurvey ? (
        <ReportActivityModal
          handleClose={() => setShowSurvey(false)}
          survey={props.activity}
        />
      ) : null}
    </>
  );
};

ReportActivityCta.defaultProps = {
  className: null,
  onCtaClick: () => {},
};

ReportActivityCta.propTypes = {
  activity: SurveyActivityPropType.isRequired,
  className: PropTypes.string,
  onCtaClick: PropTypes.func,
};

export default ReportActivityCta;
