const Events = {
  BrandsPage: {
    BRAND_CLICK: 'BRAND_CLICK',
    BRANDS_CATEGORY_CLICK: 'BRANDS_CATEGORY_CLICK',
    FILTER: 'BRANDS_FILTER',
    LETTER_CLICK: 'BRAND_LETTER_CLICK',
    LOAD_ERROR: 'BRANDS_LOAD_ERROR',
    RELOAD: 'BRANDS_RELOAD',
    VIEW: 'BRANDS_LIST_VIEW',
  },

  Feed: {
    // Guidons
    ACCESS_GUIDON_CLICK: 'FEED_ACCESSGUIDON_CLICK',
    CATEGORY_GUIDON_CLICK: 'FEED_CATEGORYGUIDON_CLICK',
    CHALLENGE_GUIDON_CLICK: 'FEED_CHALLENGEGUIDON_CLICK',
    RETAILER_GUIDON_CLICK: 'FEED_RETAILERGUIDON_CLICK',
    EXPERTSTAB_GUIDON_CLICK: 'FEED_EXPERTSTABGUIDON_CLICK',

    BUCKET_LOAD_ERROR: 'FEED_BUCKET_ERROR',
    BUCKET_RELOAD: 'FEED_BUCKET_RELOAD',

    CAMPAIGN_VIEW: 'USERHOME_VIEW_EVENT',
    PROGRAM_CARD_VIEW: 'FEED_PROGRAM_VIEW',

    CAROUSEL_PREVIOUS: 'FEED_CAROUSEL_PREVIOUS',
    CAROUSEL_NEXT: 'FEED_CAROUSEL_NEXT',
    CAROUSEL_VIEW: 'CAROUSEL_VIEW',

    LANDINGPAGE_EMPTY_CTA_CLICK: 'FEED_LANDINGPAGE_EMPTY_CTA_CLICK',
    LANDINGPAGE_HEADER_CTA_CLICK: 'FEED_LANDINGPAGE_HEADER_CTA_CLICK',

    PRODUCT_CLICK: 'SUGGESTED_PRODUCT_CARD_CLICK',
    COMBINED_PRODUCT_BRAND_CLICK: 'FEED_COMBINED_PRODUCT_BRAND_CLICK',

    STRUCTURE_LOAD_ERROR: 'FEED_STRUCTURE_ERROR',
    STRUCTURE_CAMPAIGNS_RELOAD: 'STRUCTURE_CAMPAIGNS_RELOAD',
    STRUCTURE_RELOAD: 'FEED_STRUCTURE_RELOAD',

    WHATNOW_AFFILIATIONS_CLICK: 'FEED_WHATNOW_AFFILIATIONS_CLICK',
    WHATNOW_COMMUNITIES_CLICK: 'FEED_WHATNOW_COMMUNITIES_CLICK',
    WHATNOW_LOAD_ERROR: 'FEED_WHATNOW_ERROR',
    WHATNOW_PROFILE_CLICK: 'FEED_WHATNOW_PROFILE_CLICK',
    WHATNOW_WHY_CLICK: 'FEED_WHATNOW_WHY_CLICK',
    WHATNOW_VIEW: 'FEED_WHATNOW_VIEW',

    BACK_CLICK: 'FEED_BACK_CLICK',
    BRAND_CLICK: 'FEED_BRAND_CLICK',
    CATEGORY_CLICK: 'FEED_CATEGORY_CLICK',
    SEE_ALL_CLICK: 'FEED_SEEALL_CLICK',
  },

  Marquee: {
    MARQUEE_LOAD_ERROR: 'MARQUEE_ERROR',
    MARQUEE_MESSAGE_CLICK: 'MARQUEE_MESSAGE_CLICK',
    MARQUEE_MESSAGE_VIEW: 'MARQUEE_MESSAGE_VIEW',
    MARQUEE_NEXT: 'MARQUEE_NEXT',
    MARQUEE_PREVIOUS: 'MARQUEE_PREVIOUS',
    MARQUEE_VIEW: 'MARQUEE_VIEW',
  },

  Prompts: {
    CARD_CLICK: 'PROMPTCARD_CLICK',
    CARD_DISMISS: 'CARD_DISMISS',
    CARD_DISMISS_COMPLETED: 'CARD_DISMISS_COMPLETED',
    CARD_VIEW: 'PROMPTCARD_VIEW',

    // Reconnect prompt
    RECONNECT_CLICK: 'PROMPTCARD_RECONNECT_CLICK',
    RECONNECT_MODAL_CANCEL: 'SOCIAL_CARD_FAILURE_NO_THANKS_CLICK',
    RECONNECT_MODAL_CLOSE: 'SOCIAL_CARD_MODAL_CLOSE',
    RECONNECT_MODAL_OK: 'SOCIAL_CARD_SUCCESS_OK_CLICK',
    RECONNECT_MODAL_TRYAGAIN: 'SOCIAL_CARD_FAILURE_TRY_AGAIN_CLICK',

    // Verify email prompt
    RESEND_FAILURE: 'PROMPTCARD_RESEND_EMAIL_FAILURE',
    RESEND_SUCCESS: 'PROMPTCARD_RESEND_EMAIL_SUCCESS',
  },

  Search: {
    FOLLOW_USER_TOGGLE: 'FOLLOW_USER_TOGGLE',
    SEARCH: 'SEARCH',
    SEARCH_BRANDS: 'SEARCH_BRANDS',
    SEARCH_BRANDS_CLICK: 'CARD_CLICK',
    SEARCH_BRANDS_TOP_VIEW: 'BRAND_SEARCH_TOP_RESULT_VIEWED',
    SEARCH_EXPERT_CLICK: 'SEARCH_EXPERT_CLICK',
    SEARCH_EXPERTS: 'SEARCH_EXPERTS',
    SEARCH_EXPERTS_REFER: 'SEARCH_EXPERTS_REFER',
    SEARCH_MODULE_CLICK: 'SEARCH_MODULE_CLICK',
    SEARCH_MODULES: 'SEARCH_MODULES',
    SEARCH_PRODUCTS: 'SEARCH_PRODUCTS',
    SEARCH_REFINE: 'SEARCH_REFINE',
    SEARCH_REFINE_RESULTS: 'SEARCH_REFINE_RESULTS',
    SEARCH_RETRY: 'SEARCH_RETRY',
    SEARCH_SORT: 'SEARCH_SORT_RESULT',
    SEARCH_TAB_CHANGE: 'SEARCH_TAB_CHANGE',
  },

  TopicPage: {
    MODULES_AFFILIATE_CLICK: 'TOPIC_MODULES_AFFILIATE_CLICK',
    MODULE_CLICK: 'TOPIC_MODULE_CLICK',
    MODULES_FILTER_CLICK: 'TOPIC_MODULES_FILTER_CLICK',
    MODULES_FILTER_OPEN: 'TOPIC_MODULES_FILTER_OPEN',
    MODULES_LOAD_ERROR: 'TOPIC_MODULES_LOAD_ERROR',
    MODULES_PAGE_CLICK: 'TOPIC_MODULES_PAGE_CLICK',
    MODULES_RELOAD: 'TOPIC_MODULES_RELOAD',
    RELATED_CLICK: 'RELATED_TOPIC_CLICK',
    RELATED_LOAD_ERROR: 'RELATED_TOPICS_LOAD_ERROR',
    RELATED_NEXT: 'RELATED_TOPICS_NEXT',
    RELATED_PREVIOUS: 'RELATED_TOPICS_PREVIOUS',
    RELATED_VIEW: 'RELATED_TOPICS_VIEW',
    TOPIC_LOAD_ERROR: 'TOPIC_LOAD_ERROR',
    TOPIC_RELOAD: 'TOPIC_RELOAD',
    VIEW: 'TOPIC_VIEW',
  },

  TopicsPage: {
    LETTER_CLICK: 'TOPICS_LETTER_CLICK',
    LOAD_ERROR: 'TOPICS_LOAD_ERROR',
    RELOAD: 'TOPICS_RELOAD',
    TOPIC_CLICK: 'TOPIC_CLICK',
    VIEW: 'TOPICS_LIST_VIEW',
  },
};

export default Events;
