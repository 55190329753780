import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { STARS_MAX } from 'ERC/recommendations/utils/constants';
import Star from 'ERC/utilities/components/Star';

import { sendEvent } from '../../utils/analytics';

import './reviewCard.less';

const ReviewCard = ({ className = '', index, listMode, review }) => {
  const handleClick = useCallback(() => {
    sendEvent('BRAND_PAGE_RECOMMENDATIONS_TILE_CLICK', {
      index,
      orgId: review.entityData.ownerId,
      orgName: review.entityData.ownerDescription,
      productCode: review.entityData.id,
      rating: review.metadata.rating,
      recommendationId: review.id,
    });
  }, [index, review]);

  return (
    <a
      className={`community-review-card ${className}`}
      data-list-mode={!!listMode}
      data-review-id={review.id}
      href={review.url}
      key={review.id}
      onClick={handleClick}
    >
      {review.entityData.imageUrl && (
        <img
          alt={review.entityData.description}
          className="review-product-image"
          src={review.entityData.imageUrl}
          title={review.entityData.description}
        />
      )}
      <div className="review-details">
        <div className="review-stars">
          {Array.from(Array(STARS_MAX).keys()).map(idx => (
            <Star
              key={idx}
              filled={(idx + 1) <= +review.metadata.stars}
            />
          ))}
        </div>
        <div className="review-text">
          {review.title && (
            <span className="review-title">
              {review.title}
            </span>
          )}
          <span className="review-body">
            {review.body}
          </span>
        </div>
        <div className="review-creator">
          <span className="reviewer-name">
            {review.creatorData.displayName}
          </span>
          <i className="exp-ux-bolt exp-ux-mini" />
        </div>
      </div>
    </a>
  );
};

ReviewCard.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  listMode: PropTypes.bool,
  review: PropTypes.shape({
    attributes: PropTypes.any,
    body: PropTypes.string.isRequired,
    creatorData: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    entityData: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      imageUrl: PropTypes.string,
      ownerDescription: PropTypes.string.isRequired,
      ownerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      rating: PropTypes.string.isRequired,
      stars: PropTypes.string,
    }).isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default memo(ReviewCard);
