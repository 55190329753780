import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

// todo: replace react-waypoint with intersection observer

/**
 * Infinite Scrolling List Component
 */
const InfiniteScrollingList = (props) => (
  <>
    {props.items.map((item, index) => props.renderItem(item, index))}
    <Waypoint
      onEnter={() => setTimeout(() => {
        if (!props.loading && props.hasMore) {
          // Fetch the next page
          props.loadMore();
        }
      }, 0)}
      scrollableAncestor={props.scrollableAncestor}
    />
  </>
);

InfiniteScrollingList.defaultProps = {
  hasMore: true,
  items: null,
  loading: false,
  scrollableAncestor: undefined,
};

InfiniteScrollingList.propTypes = {
  hasMore: PropTypes.bool,
  items: PropTypes.array,
  loading: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  scrollableAncestor: PropTypes.any,
};

export default memo(InfiniteScrollingList);
