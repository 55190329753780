import React, { memo } from 'react';
import PropTypes from 'prop-types';

import getExpMessage from 'ERC/localization/getExpMessage';

import CommunityHero from './communityHero';
import { BrandAccessStatuses } from '../brandAccessStatus';
import BrandAccessDetails from './brandAccessDetails';
import './communitySummary.less';

/**
 * Community Summary Component
 */
const CommunitySummary = (props) => (
  <div className="community-summary">
    <CommunityHero images={props.images} />
    <div className="community-summary-container container">
      <div
        className={`community-avatar${props.avatar ? '' : ' empty-avatar'}`}
        style={{ backgroundImage: props.avatar ? `url(${props.avatar})` : null }}
      />
      <div className="community-details">
        <h1 className="community-name">{props.name}</h1>
        {props.orgId && (
          <BrandAccessDetails
            brandAccessLevel={props.brandAccessLevel}
            orgId={props.orgId}
            orgName={props.name}
            teamName={props.pendingTeam?.name ||
                getExpMessage({
                  defaultMessage: 'a group',
                  messageKey: 'community.summary.accessModal.teamFallback',
                })}
          />
        )}
      </div>
      <div className="community-description">{props.description}</div>
      {props.children && (
        <div className="cta-button">
          {props.children}
        </div>
      )}
    </div>
  </div>
);

CommunitySummary.defaultProps = {
  avatar: '',
  children: null,
  className: '',
  description: '',
  images: null,
  name: '',
  orgId: null,
};

CommunitySummary.propTypes = {
  avatar: PropTypes.string,
  brandAccessLevel: PropTypes.oneOf(Object.values(BrandAccessStatuses)),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.shape({
    DESKTOP: PropTypes.shape({
      uri: PropTypes.string,
    }),
    MOBILE: PropTypes.shape({
      uri: PropTypes.string,
    }),
    TABLET: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
  name: PropTypes.string,
  orgId: PropTypes.number,
  pendingTeam: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default memo(CommunitySummary);
