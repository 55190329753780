import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const UGCShareModal = React.lazy(() => import(/* webpackChunkName: 'ugcShareModal' */ 'ERC/ugcShareModal'));

/**
 * Lazy UGC Share Modal Component
 */
const LazyUgcShareModal = ({ fallback, ...props }) => (
  <Suspense fallback={fallback || null}>
    <UGCShareModal {...props} />
  </Suspense>
);

LazyUgcShareModal.defaultProps = {
  brandTags: null,
  campaignId: null,
  fallback: null,
  onPostSubmitted: () => {},
  orgId: null,
  productTags: null,
  user: null,
};

LazyUgcShareModal.propTypes = {
  brandTags: PropTypes.arrayOf(PropTypes.shape({
    orgAvatar: PropTypes.string,
    orgKey: PropTypes.string,
    orgName: PropTypes.string,
  })),
  campaignId: PropTypes.number,
  fallback: PropTypes.node,
  onCloseClick: PropTypes.func.isRequired,
  onPostSubmitted: PropTypes.func,
  orgId: PropTypes.number,
  productTags: PropTypes.arrayOf(PropTypes.shape({
    orgId: PropTypes.number.isRequired,
    orgName: PropTypes.string,
    productCode: PropTypes.string.isRequired,
    productDetailPageUrl: PropTypes.string,
    productImageFilename: PropTypes.string,
    productImageUrl: PropTypes.string,
    productName: PropTypes.string,
  })),
  user: PropTypes.object,
};

export default LazyUgcShareModal;
