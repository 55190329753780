import React from 'react';
import PropTypes from 'prop-types';

import { ErrorAlert } from 'ERC/alert/Alert';

/**
 * Error Boundary Component
 */
class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error('Error caught', error, errorInfo);
  }

  render() {
    if (this.state.error) {
      if (!this.props.visible) {
        return null;
      }

      return (
        <div className="container">
          <ErrorAlert>
            {this.props.message}
          </ErrorAlert>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  message: 'An error occurred',
  visible: false,
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  message: PropTypes.string,
  visible: PropTypes.bool,
};

export default ErrorBoundary;
