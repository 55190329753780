import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import uniqWith from 'lodash/uniqWith';
import { bindActionCreators } from 'redux';
import URI from 'urijs';

import OrgClientStatuses from '../orgClientStatuses';
import { PageTypesByType } from '../PageTypes';
import xhr from '../../utils/xhr';

import {
  // Reexport the UGC Comment & Like actions
  handleCommentUGC as postUgcComment,
  handleDeleteUGC as deleteUgc,
  handleEditUGC as editUgc,
  handleLikeUGC as toggleUgcLike,
  handlePostUGC as postUgc,
  removeUgcBrandTag,
} from '../../expertsTab/redux/expertsActions';

export {
  deleteUgc,
  editUgc,
  postUgc,
  postUgcComment,
  removeUgcBrandTag,
  toggleUgcLike,
};

export const CLEAR_FEATURED_PRODUCTS = 'CLEAR_FEATURED_PRODUCTS';
export const FETCH_ACTIVITY_FEED = 'FETCH_ACTIVITY_FEED';
export const FETCH_BRAND_BANNERS = 'FETCH_BRAND_BANNERS';
export const FETCH_BRAND_CONTENT = 'FETCH_BRAND_CONTENT';
export const FETCH_CAMPAIGN_AND_CERTIFICATION = 'FETCH_CAMPAIGN_AND_CERTIFICATION';
export const FETCH_CERTIFICATION = 'FETCH_CERTIFICATION';
export const FETCH_COMMUNITIES = 'FETCH_COMMUNITIES';
export const FETCH_PROFILE_NEXT_TUTORIAL = 'FETCH_PROFILE_NEXT_TUTORIAL';
export const FETCH_RELATED_BRANDS = 'FETCH_RELATED_BRANDS';
export const FETCH_UGC = 'FETCH_UGC';
export const FETCH_VOTES = 'FETCH_VOTES';
export const LOAD_USER = 'LOAD_USER';
export const REMOVE_SURVEY = 'REMOVE_SURVEY';

export const DEFAULT_COMMUNITY_MEMBER_COUNT = 9;
const ACTIVITY_FEED_DEFAULT_PAGE_SIZE = 30;
const PEW_PEW_PHOTO_SUBJECTS = ['bow', 'dead_animal', 'gun', 'handgun', 'rifle'];

export const checkAndDismissDeniedStatus =
  createAsyncThunk('community/deniedStatusCheck/fetch', async (_, { getState }) => {
    const {
      teamId,
      uuid,
    } = getState().community || {};

    const application = await xhr
      .get(`/xapi/user-affiliations/ext/1.0/affiliations/users/${uuid}/teamId/${teamId}`)
      .then(res => res.data);

    if (!application) {
      return null;
    }

    const dismissKey = `${teamId}-${application.teamApplicationId}`;
    const dismissType = 'prompt-deniedApplication';

    const existingDismissal = await xhr
      .get(`/xapi/user-interaction/ext/1.0/interaction/${dismissType}/${dismissKey}`)
      .then(res => res.data?.pop());

    if (existingDismissal) {
      return existingDismissal;
    }

    const dismissal = {
      effectiveTime: Date.now() + (1000 * 60 * 60 * 24),
      identifier: dismissType,
      objectId: dismissKey,
      userUuid: uuid,
    };

    return xhr
      .post('/xapi/user-interaction/ext/1.0/interaction', dismissal)
      .then(res => res?.data);
  });

export const fetchActivityFeed = () => (dispatch, getState) => {
  const { community } = getState();
  const { activityNextPageFilters, id } = community || {};
  const { token: previewToken } = community?.previewConfig || {};

  const params = activityNextPageFilters || {
    itemTypesMaxTime: {
      MESSAGE: null,
      QUESTION: null,
      POLL: null,
    },
    source: 'SUGGESTED',
    sort: 'NEW',
    categoryIds: [],
    pageSize: ACTIVITY_FEED_DEFAULT_PAGE_SIZE,
    placements: [{
      placementType: 'COMMUNITY',
      referenceId: id,
    }],
    previewToken,
  };

  return dispatch({
    payload: xhr('/xapi/user-content/ext/3.0/expertsTab', 'post', params)
      .then(res => res.data || {}) || [],
    type: FETCH_ACTIVITY_FEED,
  });
};

export const fetchAffiliations =
  createAsyncThunk('user/affiliations/fetch', async () =>
    xhr.get('/xapi/profile/ext/1.0/affiliations').then((res) => res?.data));

export const fetchBrandAwards = createAsyncThunk(
  'community/brandAwards/fetch',
  async ({ acquisition, page, size, sort = 'id,desc' }, { getState, fulfillWithValue }) => {
    const {
      org: { orgId },
    } = getState().community || {};

    const params = new URLSearchParams({
      orgId,
      sort, // Newest first
      view: 'full',
    });

    if (page) {
      params.set('page', page);
    }

    if (size) {
      params.set('size', acquisition ? (size + 1) : size);
    }

    const response = await xhr(`/xapi/award/pub/1.0/awards?${params.toString()}`, 'get');
    return fulfillWithValue(response.data, { acquisition });
  },
);

export const fetchBrandBanners = () => (dispatch, getState) => {
  const { campaignSearchURI } = getState().community || {};
  return dispatch({
    payload: xhr(campaignSearchURI, 'get')
      .then(res => (res.data.content || {}).BRAND_BANNER || []),
    type: FETCH_BRAND_BANNERS,
  });
};

export const fetchBrandContent = () => (dispatch, getState) => {
  const { knowledgeContentExternalURI, org: { orgId } } = getState().community || {};
  return dispatch({
    type: FETCH_BRAND_CONTENT,
    payload: xhr(`${knowledgeContentExternalURI}/1.0/contentFolders/org/${orgId}/list`, 'get')
      .then(res => {
        const brandContent = res.data || [];

        const recentContentArticles =
          uniqWith(brandContent
            .reduce((content, { contentArticles, contentFolderId }) => [
              ...content,
              ...contentArticles.map(article => ({
                ...article,
                topicId: contentFolderId,
              }))], [])
            // eslint-disable-next-line max-len
            .sort((a, b) => (b.startDate - a.startDate)), ({ contentArticleId: aId }, { contentArticleId: bId }) => aId === bId);

        return {
          brandContent,
          recentContentArticles,
        };
      })
      .catch(() => ({
        brandContent: {},
        recentContentArticles: [],
      })),
  });
};

export const fetchVotesForUser = () => (dispatch) => (
  dispatch({
    payload: xhr('/xapi/voice/ext/1.0/review/votes', 'get')
      .then(res => res.data || {}),
    type: FETCH_VOTES,
  })
);

export const fetchCampaigns = createAsyncThunk('community/campaigns/fetch',
  async (_, { getState }) => {
    const { campaignSearchURI } = getState().community || {};
    const ranker = (a, b) => a.rank - b.rank;

    return xhr
      .get(campaignSearchURI)
      .then(res => {
        const { content = {} } = res.data || {};
        return {
          ...res,
          campaigns: [
            ...(content.SURVEY || []),
            ...(content.CAMPAIGN || []),
            ...(content.GENERIC || []),
          ].sort(ranker),
          banners: (content.BANNER || []).sort(ranker),
          brandBanners: (content.BRAND_BANNER || []).sort(ranker),
          programs: (content.PROGRAM || []).sort(ranker),
        };
      });
  });

export const fetchCertificationData = (campaignIds = []) => (dispatch) => dispatch({
  type: FETCH_CERTIFICATION,
  payload: !campaignIds.length ? Promise.resolve({}) :
    xhr(`/xapi/user-knowledge/ext/1.0/campaign/certification?campaignId=${campaignIds.join(',')}`, 'get'),
});

export const fetchCampaignAndCertification = () => (dispatch, getState) => dispatch({
  payload: fetchCampaigns()(dispatch, getState)
    .then(res => {
      const { campaigns = [] } = res.payload || {};
      const campaignIds = campaigns.filter(c => c.id).map(c => c.id);

      // Fetch the certification data for the returned campaignIds
      return fetchCertificationData(campaignIds)(dispatch, getState);
    }),
  type: FETCH_CAMPAIGN_AND_CERTIFICATION,
});

export const fetchBrandPageContent = () => (dispatch, getState) => {
  const campaignPromise = fetchCampaigns()(dispatch, getState);

  const bannerPromise = dispatch({
    payload: campaignPromise.then(res => res?.payload?.brandBanners || []),
    type: FETCH_BRAND_BANNERS,
  });

  const certsPromise = dispatch({
    payload: campaignPromise.then(res => {
      const campaigns = res.payload?.campaigns || [];
      const campaignIds = campaigns.filter(c => c.id).map(c => c.id);

      // Fetch the certification data for the returned campaignIds
      return fetchCertificationData(campaignIds)(dispatch, getState);
    }),
    type: FETCH_CAMPAIGN_AND_CERTIFICATION,
  });

  const communityPromise = dispatch({
    payload: campaignPromise.then(res => ({ data: res.payload?.programs || [] })),
    type: FETCH_COMMUNITIES,
  });

  return Promise.all([
    bannerPromise,
    campaignPromise,
    certsPromise,
    communityPromise,
  ]);
};

export const fetchProgramPageContent = () => (dispatch, getState) => {
  const campaignPromise = fetchCampaigns()(dispatch, getState);

  const communityPromise = dispatch({
    payload: campaignPromise.then(res => ({ data: res.payload?.programs || [] })),
    type: FETCH_COMMUNITIES,
  });

  return Promise.all([campaignPromise, communityPromise]);
};

export const fetchFeaturedProducts = createAsyncThunk(
  'community/featuredProducts/fetch',
  async (numProducts, { dispatch, getState, rejectWithValue }) => {
    const { orgId, previewCampaign } = getState().community || {};
    const searchConfig = {
      filters: {
        ORGANIZATION: [orgId].flat().map(id => `${id}`),
        IN_STOCK_DEAL: ['true'],
      },
      maxResults: numProducts,
      providerTimeoutMS: 1500,
      startResults: 0,
      sortDirection: 'DESC',
      sortField: 'FEATURED_SORT_ORDER',
    };
    if (previewCampaign?.stores) {
      if (previewCampaign.stores.length) {
        searchConfig.filters.DEAL = previewCampaign?.stores.map(s => s.id);
      } else {
        dispatch({
          payload: null,
          type: CLEAR_FEATURED_PRODUCTS,
        });
      }
    }

    try {
      const result = await xhr.post('/xapi/store-services/ext/v1/stores/search/products', {
        searchConfiguration: searchConfig,
      });
      return result.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchFavoritesContent =
  createAsyncThunk('community/favoritesContent/fetch', async (_, { dispatch, getState }) => {
    /* eslint no-use-before-define: ['error', { variables: false }] */
    const {
      id: pageId,
      includeFeaturedProducts,
      isMember,
      org: { orgId },
      pageType,
      recentBrandReviews,
      showReviews,
      teamId,
      teaserBrands,
      topSellers,
    } = getState().community || {};

    const isBrandPage = pageType === PageTypesByType.MANUFACTURER.type;
    const promises = [];

    if (isMember) {
      promises.push(dispatch(fetchPageTopics()));
    }

    if (isBrandPage && includeFeaturedProducts && !topSellers) {
      promises.push(dispatch(fetchTopSellers({ orgId })));
    }

    if (showReviews && !recentBrandReviews) {
      promises.push(dispatch(fetchRecentBrandReviews(isBrandPage ? ({ orgId }) : ({ teamId }))));
    }

    if (!isBrandPage && !teaserBrands) {
      promises.push(dispatch(fetchTeaserBrands({ pageId })));
    }

    return Promise.all(promises);
  });

const fetchCommunityMembers = ({ id, ...params }) => {
  const uri = URI(`/xapi/community/ext/1.0/page/id/${id}/members`).addSearch(params);
  return xhr.get(uri)
    .then((res) => res.data);
};

export const fetchFollowedMembers = createAsyncThunk(
  'community/members/followed/fetch',
  ({ page, size } = {}, { getState } = {}) => {
    const { id } = getState().community || {};
    return fetchCommunityMembers({ follow: true, id, page, size, sort: 'followedSince,desc' });
  },
);

export const fetchHasReviewableProducts =
  createAsyncThunk('community/hasReviewableProducts/fetch', async ({ orgId }) =>
    xhr.post('/xapi/store-services/ext/v1/archive/search/products', {
      searchConfiguration: {
        filters: {
          ORGANIZATION: [orgId],
        },
        maxResults: 0,
      },
    }).then(res => !!res?.data?.totalResults));

export const fetchHeroUGC =
  createAsyncThunk('community/heroUGC/fetch', async ({ orgKey, teamId }) => {
    const params = new URLSearchParams({
      excludedSubjects: PEW_PEW_PHOTO_SUBJECTS,
      hasPublicProfile: true,
      mode: 'SHOULD',
      size: 2,
      subjects: ['person'],
    });

    if (orgKey) {
      params.set('brand', orgKey);
    }

    if (teamId) {
      params.set('teamIds', [teamId]);
    }

    return xhr
      .get(`/xapi/ugc/pub/2.0/content?${params.toString()}`)
      .then(res => res.data?.results);
  });

export const fetchMembers = createAsyncThunk(
  'community/members/fetch',
  (params = {}, { getState } = {}) => {
    const { id } = getState().community || {};
    return fetchCommunityMembers({ id, sort: 'displayName', ...params });
  },
);

export const fetchTopMembers = createAsyncThunk(
  'community/members/top/fetch',
  (params = {}, { getState } = {}) => {
    const { id } = getState().community || {};
    return fetchCommunityMembers({ id, sort: ['avatarIsDefault', 'displayName'], ...params });
  },
);

export const fetchMemberSummary = createAsyncThunk(
  'community/members/summary/fetch',
  (params = {}, { getState } = {}) => {
    const { id } = getState().community || {};
    return fetchCommunityMembers({ id, page: 0, size: 1, ...params }); // needs to be at least 1
  },
);

export const fetchNeighbors = createAsyncThunk(
  'community/members/neighbors/fetch',
  ({ page } = {}, { getState } = {}) => {
    // future todo: this will eventually request a random selection directly from the api
    const { id } = getState().community || {};
    return fetchCommunityMembers({ id, page, size: 100 });
  },
);

export const fetchOrgAccessLevels = createAsyncThunk('orgAccess/fetch', async () => {
  try {
    const res = await xhr('/xapi/campaign/ext/v1/targetedContent/access', 'get');
    // convert the result from an array to an orgId-keyed map
    return {
      orgAccessLevels: res.data.reduce((map, targetedOrg) => ({
        ...map,
        [targetedOrg.orgId]: targetedOrg,
      }), {}),
    };
  } catch (e) {
    return { orgAccessLevels: {} };
  }
});

export const fetchPageTopics = createAsyncThunk('community/topics/fetch', (_, { getState }) => {
  const { orgId, pageType, teamId } = getState().community;

  const params = new URLSearchParams({ sort: 'frequency,desc' });
  switch (pageType) {
    case PageTypesByType.GROUP.type:
    case PageTypesByType.RETAILER.type:
      params.set('teamId', teamId);
      break;
    case PageTypesByType.MANUFACTURER.type:
      params.set('orgId', orgId);
      break;
    default:
      return [];
  }

  return xhr.get(`/xapi/knowledge-content/ext/1.0/modules/topics/targeted?${params.toString()}`)
    .then(res => res.data);
});

export const fetchProfileNextTutorial = (pathType) => (dispatch) => dispatch({
  type: FETCH_PROFILE_NEXT_TUTORIAL,
  payload: xhr(`/xapi/profile/ext/1.0/prompts/tutorial/path/${pathType}`, 'get')
    .then(res => {
      // Combine available tasks / completed taks then sort.
      const tasks = [];
      res.data.availableTasks.forEach(t => {
        tasks.push({ ...t, completed: false });
      });

      res.data.completedTasks.forEach(t => {
        tasks.push({ ...t, completed: true });
      });

      return tasks.sort((a, b) => (a.rank - b.rank));
    })
    .catch(() => ([])),
});

export const fetchRecentBrandReviews =
  createAsyncThunk('community/brandRecs/fetch', async ({ orgId, teamId, pageSize }) =>
    // fetch the 24 most recent featured reviews that have star ratings
    xhr.post('/xapi/voice/pub/1.0/socialism/search', {
      annotations: [{
        name: 'Featured',
        parentName: 'Uplevel',
      }],
      attributes: [{
        key: 'stars',
      }],
      entityOwnerId: orgId,
      excludeAnnotations: [{
        name: 'Unfeatured',
        parentName: 'Uplevel',
      }],
      pageSize: pageSize || 24,
      searchTeam: teamId ? ({
        teamIds: [teamId],
      }) : undefined,
      sorts: [{
        direction: 'desc',
        field: 'createdTime',
      }],
      types: ['REVIEW'],
    }).then(res => res?.data));

export const fetchRelatedBrands = () => (dispatch, getState) => {
  const {
    org: { orgId },
    orgAccessLevels,
    organizationExternalURI,
    organizationPublicURI,
    uuid,
  } = getState().community || {};

  // Use the pub url for logged out pages
  const orgURI = uuid ? organizationExternalURI : organizationPublicURI;

  // Filter down to only the Active Orgs that the user is targeted by
  const targetedOrgIds = Object.keys(orgAccessLevels || {})
    .map((key) => orgAccessLevels[key])
    .filter(({ orgClientStatus, targeted }) => targeted
      && orgClientStatus === OrgClientStatuses.ACTIVE)
    .map((accessLevel) => accessLevel.orgId);

  return dispatch({
    type: FETCH_RELATED_BRANDS,
    payload: xhr(`${orgURI}/v1/brand/affinity/list/${orgId}`, 'post', {
      targetedOrgIds,
    })
      .then(res => ({
        brandAffinities: res.data,
      }))
      .catch(() => ({
        brandAffinities: [],
      })),
  });
};

export const fetchTeaserBrands =
  createAsyncThunk('page/teaserBrands/fetch', async ({ pageId }) =>
    xhr
      .get(`/xapi/community/pub/1.0/page/id/${pageId}/teaser/brands`)
      .then((res) => res?.data));

export const fetchTeaserProducts =
  createAsyncThunk('page/teaserProducts/fetch', async ({ pageId }) =>
    xhr.get(`/xapi/community/pub/1.0/page/${pageId}/teaser/product/bucket/enrich`)
      .then((res) => res?.data));

export const fetchTeaserTeams =
  createAsyncThunk('page/teaserTeams/fetch', async ({ pageId }) =>
    xhr
      .get(`/xapi/community/pub/1.0/page/id/${pageId}/teaser/teams`)
      .then((res) => res?.data));

export const fetchGroupSearchResults =
  createAsyncThunk('page/groupSearch/fetch', async ({ searchTerm, teamClassId }) =>
    xhr
      .post(
        '/xapi/user-affiliations/pub/1.0/teams?size=40',
        {
          name: searchTerm,
          teamClassIds: [teamClassId],
        },
      )
      .then(res => res?.data));

export const fetchTopSellers = createAsyncThunk(
  'page/topSellers/fetch',
  async ({ orgId, pageSize = 24 }, { rejectWithValue }) => {
    const searchConfig = {
      filters: {
        ORGANIZATION: [orgId].flat().map(id => `${id}`),
      },
      maxResults: pageSize,
      providerTimeoutMS: 1500,
      startResults: 0,
      sortDirection: 'DESC',
      sortField: 'FEATURED_SORT_ORDER',
    };

    try {
      const result = await xhr.post('/xapi/store-services/pub/v1/preview/search/products', {
        searchConfiguration: searchConfig,
      });
      return result.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchUGC = ({ size, teamIds, teamOnly } = {}) => (dispatch, getState) => {
  const {
    isAuthenticated,
    categories,
    org: { orgKey },
    ugcPagination: { creationDate = '' } = {},
  } = getState().community || {};

  const params = teamOnly ? {} : {
    mode: 'SHOULD',
    hashtag: orgKey,
    brand: orgKey,
  };

  if (size) {
    params.size = size;
  }

  if (teamIds) {
    params.teamIds = teamIds;
  }

  if (creationDate) {
    params.moreBefore = creationDate;
  }

  // Exclude hunt(19)/tactical(33) content if the page isn't aligned with those categories
  const includePewPew = categories?.some((c) => [19, 33].includes(c?.id));
  if (!includePewPew) {
    params.excludedSubjects = PEW_PEW_PHOTO_SUBJECTS;
  }

  const url = URI(`/xapi/ugc/${isAuthenticated ? 'ext' : 'pub'}/2.0/content`).addSearch(params);

  return dispatch({
    meta: { creationDate, size },
    type: FETCH_UGC,
    payload: xhr.get(url, 'get'),
  });
};

export const loadUser = (uuid) => ({
  meta: { uuid },
  type: LOAD_USER,
  payload: window.Exp.user.load(uuid),
});

export const removeSurveyCard = (card) => ({
  type: REMOVE_SURVEY,
  payload: { id: card.id },
});

export const updateActivityFeedItem = createAction('community/feedItem/update');
export const updateRecentBrandReviews = createAction('community/brandRecs/update');
export const updateUserVotes = createAction('community/userVotes/update');

const CommunityActionsDispatch = (dispatch) => bindActionCreators({
  checkAndDismissDeniedStatus,
  deleteUgc,
  editUgc,
  fetchActivityFeed,
  fetchBrandBanners,
  fetchBrandContent,
  fetchBrandPageContent,
  fetchCampaignAndCertification,
  fetchCampaigns,
  fetchFavoritesContent,
  fetchFeaturedProducts,
  fetchFollowedMembers,
  fetchHasReviewableProducts,
  fetchHeroUGC,
  fetchMembers,
  fetchMemberSummary,
  fetchNeighbors,
  fetchOrgAccessLevels,
  fetchProgramPageContent,
  fetchRecentBrandReviews,
  fetchRelatedBrands,
  fetchGroupSearchResults,
  fetchTeaserBrands,
  fetchTeaserTeams,
  fetchTeaserProducts,
  fetchTopSellers,
  fetchUGC,
  fetchVotesForUser,
  loadUser,
  postUgc,
  postUgcComment,
  removeSurveyCard,
  removeUgcBrandTag,
  toggleUgcLike,
  updateActivityFeedItem,
  updateRecentBrandReviews,
  updateUserVotes,
}, dispatch);

export default CommunityActionsDispatch;
