import { configureStore } from '@reduxjs/toolkit';
import immutableStateVariant from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

const isDev = process.env.NODE_ENV !== 'production' && !process.env.TEST;

// Compose the middleware
const middleware = [thunk, promise];

if (isDev) {
  // Add in a logger in dev
  middleware.push(createLogger({
    collapsed: true, // not too noisy
  }));

  // Add in a library to be noisy when state is changed (dev only!)
  middleware.push(immutableStateVariant());
}

const createStore = ({
  devTools = isDev,
  middleware: additionalMiddleware = [],
  name,
  preloadedState = {},
  reducer,
}) => configureStore({
  devTools: !devTools ? false : (name ? { name } : devTools),
  middleware: middleware.concat(additionalMiddleware),
  preloadedState,
  reducer,
});

export default createStore;
