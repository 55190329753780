import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';
import UGCCard from 'ERC/ugc/UGCCard';
import { UGC_SOURCE } from 'ERC/ugc/utils/constants';

import CommunityActionsDispatch from '../redux/actions';
import { sendEvent } from '../../utils/analytics';
import ErrorBoundary from '../../common/ErrorBoundary';
import FollowedUsersLoader from '../../common/FollowedUsersLoader';
import InfiniteScrollingList from '../../common/InfiniteScrollingList';

import './ugcContainer.less';

export const UGCLoggedOutClick = 'ugcLoggedOutClick';

const UGCContainer = (props) => {
  const {
    cdn,
    deleteUgc,
    editUgc,
    fetchUGC: doFetchUGC,
    isAuthenticated,
    isBrandTagAdmin,
    maxUgcCount,
    name,
    pageKey,
    postUgcComment,
    removeUgcBrandTag,
    toggleUgcLike,
    ugcContent,
    ugcContentEnd,
    ugcContentLoading,
    ugcPageSize,
    user,
  } = props;

  const fetchUGC = useCallback(() => {
    if (!ugcContentLoading && !ugcContentEnd) {
      doFetchUGC({ size: ugcPageSize });
    }
  }, [doFetchUGC, ugcContentEnd, ugcContentLoading, ugcPageSize]);

  useEffect(() => {
    if (!ugcContent?.length) {
      fetchUGC();
    }
  }, [fetchUGC, ugcContent?.length]);

  if (!ugcContent?.length) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className="card-container ugc-container">
        <ExpLabel
          className="content-section from-experts"
          defaultMessage="From Our Experts"
          messageKey="community.from.our.experts"
          tagName="div"
        />
        <InfiniteScrollingList
          hasMore={!ugcContentEnd && maxUgcCount && ugcContent.length < maxUgcCount}
          items={ugcContent // only items with a large variant
            .filter(item => item.images && item.images.large)}
          loading={ugcContentLoading}
          loadMore={fetchUGC}
          renderItem={(item, index) => (
            <UGCCard
              analyticsSendEvent={sendEvent}
              authenticated={isAuthenticated}
              brandTagAdmin={isBrandTagAdmin ? pageKey : ' '}
              cardIndex={index}
              cdn={cdn}
              handleNoAuthClickEvent={() => {
                sendEvent(UGCLoggedOutClick, { mfgName: name, ugcId: item.id });
                if (window.ExpOnboarding) {
                  window.ExpOnboarding.signIn({
                    complete: () => window.location.reload(),
                  });
                }
              }}
              key={`ugc-${item.id}`}
              onComment={postUgcComment}
              onDelete={deleteUgc}
              onEdit={editUgc}
              onLikeToggle={toggleUgcLike}
              onRemoveBrandTag={removeUgcBrandTag}
              source={UGC_SOURCE.SOURCE_BRAND_PAGE}
              ugcCard={item}
              user={user}
            />
          )}
        />
      </div>
      {isAuthenticated ? (
        <FollowedUsersLoader />
      ) : null}
    </ErrorBoundary>
  );
};

UGCContainer.defaultProps = {
  ugcContentEnd: false,
  ugcContentLoading: false,
  ugcPageSize: 12,
};

UGCContainer.propTypes = {
  cdn: PropTypes.string.isRequired,
  deleteUgc: PropTypes.func.isRequired,
  editUgc: PropTypes.func.isRequired,
  fetchUGC: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isBrandTagAdmin: PropTypes.bool,
  loadUser: PropTypes.func.isRequired,
  maxUgcCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  pageKey: PropTypes.string,
  postUgcComment: PropTypes.func.isRequired,
  removeUgcBrandTag: PropTypes.func.isRequired,
  toggleUgcLike: PropTypes.func.isRequired,
  ugcContent: PropTypes.array.isRequired,
  ugcContentEnd: PropTypes.bool,
  ugcContentLoading: PropTypes.bool,
  ugcPageSize: PropTypes.number,
  ugcPagination: PropTypes.object,
  user: PropTypes.object,
  uuid: PropTypes.string,
};

const mapStateToProps = (state) => ({
  cdn: state.community.cdn,
  isAuthenticated: state.community.isAuthenticated,
  pageKey: state.community.pageKey,
  isBrandTagAdmin: state.community.isBrandTagAdmin,
  ugcContent: state.community.ugcContent,
  ugcContentEnd: state.community.ugcContentEnd,
  ugcContentLoading: state.community.ugcContentLoading,
  ugcPagination: state.community.ugcPagination,
  user: state.community.user,
  uuid: state.community.uuid,
});

export default connect(mapStateToProps, CommunityActionsDispatch)(UGCContainer);
