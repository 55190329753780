import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';
import getExpMessage from 'ERC/localization/getExpMessage';

import { CampaignPropType } from '../../../config/propTypes';
import ActivityCta from '../activityCta';

const CTA_ICON = {
  CART: 'cart',
  PLAY: 'play',
};

const CTA_TYPE = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
};

const ICON = {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  WARNING: 'warningTriangle',
};

const COMMON_MESSAGES = {
  ADDITIONAL_LEARNING_AVAILABLE: {
    defaultMessage: 'Additional learning is available.',
    messageKey: 'campaign.shopCta.body.additionalLearningAvailable',
  },
  ALL_UNLOCKED: {
    defaultMessage: 'All your deals are unlocked.',
    messageKey: 'campaign.shopCta.body.allUnlocked',
  },
  CONTINUE_LEARNING: {
    defaultMessage: 'Continue learning',
    messageKey: 'campaign.shopCta.cta.continueLearning',
  },
  LEARN_ONLY_BODY: {
    defaultMessage: 'This is educational content offered without a deal.',
    messageKey: 'campaign.shopCta.body.learnOnly',
  },
  LEARN_ONLY_TITLE: {
    defaultMessage: 'Learn and certify',
    messageKey: 'campaign.shopCta.title.learnOnly',
  },
  LEARN_TO_UNLOCK: {
    defaultMessage: 'Complete lessons to unlock your deal.',
    messageKey: 'campaign.shopCta.body.learnToUnlock',
  },
  LEARN_TO_UNLOCK_ALL: {
    defaultMessage: 'Complete lessons to unlock all deals.',
    messageKey: 'campaign.shopCta.cta.learnToUnlockAll',
  },
  LEARN_TO_UNLOCK_MULTI: {
    defaultMessage: 'Complete lessons to unlock your deals.',
    messageKey: 'campaign.shopCta.body.learnToUnlockMulti',
  },
  MULTI_UNLOCKED: {
    defaultMessage: 'Your deals are unlocked.',
    messageKey: 'campaign.shopCta.body.multiUnlocked',
  },
  PREVIEW_DEAL: {
    defaultMessage: 'Preview deal',
    messageKey: 'campaign.shopCta.cta.previewDeal',
  },
  SHOP_DEALS: {
    defaultMessage: 'Shop deals',
    messageKey: 'campaign.shopCta.cta.shopDeals',
  },
  SHOP_THIS_DEAL: {
    defaultMessage: 'Shop this deal',
    messageKey: 'campaign.shopCta.cta.shopThisDeal',
  },
  SINGLE_UNAVAILABLE_BODY: {
    defaultMessage: 'Learning is available. Check back soon for shopping.',
    messageKey: 'campaign.shopCta.body.singleUnavailable',
  },
  SINGLE_UNAVAILABLE_TITLE: {
    defaultMessage: 'Shopping is currently unavailable',
    messageKey: 'campaign.shopCta.title.singleUnavailable',
  },
  SINGLE_UNLOCKED: {
    defaultMessage: 'Your deal is unlocked.',
    messageKey: 'campaign.shopCta.body.singleUnlocked',
  },
  SOME_UNAVAILABLE: {
    defaultMessage: '{unavailableCount} of {totalCount} deals are currently unavailable.',
    messageKey: 'campaign.shopCta.body.xOfyDealsUnavailable',
  },
  SOME_UNLOCKED: {
    defaultMessage: '{unlockedCount} of {totalCount} deals are unlocked.',
    messageKey: 'campaign.shopCta.body.xOfyDealsUnlocked',
  },
  START_LEARNING: {
    defaultMessage: 'Start learning',
    messageKey: 'campaign.shopCta.cta.startLearning',
  },
};

const STATUS = {
  LEARN_ONLY: 'learn-only',
  LOCKED: 'locked',
  UNAVAILABLE: 'unavailable',
  UNLOCKED: 'unlocked',
};

const TYPE = {
  SHOP: 'shop',
  LEARN: 'learn',
};

const getCampaignMessage = (messageOpts) => getExpMessage({
  namespace: 'messages/campaignMessages',
  ...messageOpts,
});

const localizeOneOrMore = (messageOpts, sharedValues) => (
  Array.isArray(messageOpts)
    ? messageOpts.map((opts) => getCampaignMessage({ ...opts, values: sharedValues })).join(' ')
    : getCampaignMessage({ ...messageOpts, valuse: sharedValues })
);

/**
 * Standard Learn/Buy Campaign CTA
 */
const StoreCta = (props) => {
  const { campaign, onCtaClick, previewKey } = props;
  const isLoading = useSelector((state) => state.campaign.fetchStoresPending);

  const handleLearnLinkClick = useCallback(() => {
    onCtaClick?.('learnLink');
  }, [onCtaClick]);

  const handleStoreLinkClick = useCallback(() => {
    onCtaClick?.('storeLink', {
      locked: campaign.stores?.[0]?.locked,
    });
  }, [campaign.stores, onCtaClick]);

  if (campaign.storeData && (!campaign.stores.length || isLoading)) {
    return null;
  }

  const allLearningComplete = campaign.learnData?.allComplete;
  const allLocked = campaign.stores.every((store) => store.locked);
  const allUnlocked = campaign.stores.every((store) => !store.locked);
  const disabledCount = campaign.stores.filter((store) => store.disabled).length;
  const learningStarted = !!campaign.learnData?.completedModules;
  const someDisabled = campaign.stores.some((store) => store.disabled);
  const storeCount = campaign.stores.length;
  const storeDisabled = campaign.stores?.[0]?.disabled;
  const storeLocked = campaign.stores?.[0]?.locked;
  const unlockedCount = campaign.stores.filter((store) => !store.locked).length;

  const { completedModules, totalModules } = campaign.learnData || {};

  const ctas = [];
  const pctComplete = (totalModules ? completedModules / totalModules : 0) || null;
  let body;
  let icon;
  let statusClass;
  let title;
  let type = TYPE.SHOP;

  if (!storeCount) {
    // no stores / learn only

    body = COMMON_MESSAGES.LEARN_ONLY_BODY;
    ctas.push({
      href: campaign.learnData?.nextModuleLink,
      icon: CTA_ICON.PLAY,
      label: learningStarted ? COMMON_MESSAGES.CONTINUE_LEARNING : COMMON_MESSAGES.START_LEARNING,
      onClick: handleLearnLinkClick,
      type: CTA_TYPE.PRIMARY,
    });
    statusClass = STATUS.LEARN_ONLY;
    title = COMMON_MESSAGES.LEARN_ONLY_TITLE;
    type = TYPE.LEARN;
  } else if (storeCount === 1) {
    // single store

    if (storeDisabled) {
      body = COMMON_MESSAGES.SINGLE_UNAVAILABLE_BODY;
      ctas.push({
        href: campaign.learnData?.nextModuleLink,
        icon: CTA_ICON.PLAY,
        label: COMMON_MESSAGES.START_LEARNING,
        onClick: handleLearnLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
      icon = ICON.WARNING;
      statusClass = STATUS.UNAVAILABLE;
      title = COMMON_MESSAGES.SINGLE_UNAVAILABLE_TITLE;
    } else if (storeLocked) {
      body = COMMON_MESSAGES.LEARN_TO_UNLOCK;
      ctas.push({
        href: campaign.learnData?.nextModuleLink,
        icon: CTA_ICON.PLAY,
        label: learningStarted ? COMMON_MESSAGES.CONTINUE_LEARNING : COMMON_MESSAGES.START_LEARNING,
        onClick: handleLearnLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
      ctas.push({
        href: campaign.storeData?.storeLink,
        icon: CTA_ICON.CART,
        label: learningStarted ? COMMON_MESSAGES.SHOP_DEALS : COMMON_MESSAGES.PREVIEW_DEAL,
        onClick: handleStoreLinkClick,
        type: CTA_TYPE.OUTLINE,
      });
      icon = ICON.LOCKED;
      statusClass = STATUS.LOCKED;
    } else {
      // single store unlocked

      body = [COMMON_MESSAGES.SINGLE_UNLOCKED];

      if (!allLearningComplete) {
        body.push(COMMON_MESSAGES.ADDITIONAL_LEARNING_AVAILABLE);
      }

      ctas.push({
        href: campaign.storeData?.storeLink,
        icon: CTA_ICON.CART,
        label: allLearningComplete ? COMMON_MESSAGES.SHOP_THIS_DEAL : COMMON_MESSAGES.SHOP_DEALS,
        onClick: handleStoreLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
      icon = ICON.UNLOCKED;
      statusClass = STATUS.UNLOCKED;
    }
  } else if (allLocked) {
    // multi store, locked

    icon = ICON.LOCKED;
    statusClass = STATUS.LOCKED;

    if (someDisabled) {
      body = [COMMON_MESSAGES.LEARN_TO_UNLOCK_MULTI, COMMON_MESSAGES.SOME_UNAVAILABLE];
      ctas.push({
        href: campaign.storeData?.storeLink,
        icon: CTA_ICON.CART,
        label: COMMON_MESSAGES.SHOP_DEALS,
        onClick: handleStoreLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
      ctas.push({
        href: campaign.learnData?.nextModuleLink,
        icon: CTA_ICON.PLAY,
        label: COMMON_MESSAGES.CONTINUE_LEARNING,
        onClick: handleLearnLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
    } else {
      body = COMMON_MESSAGES.LEARN_TO_UNLOCK_MULTI;
      ctas.push({
        href: campaign.learnData?.nextModuleLink,
        icon: CTA_ICON.PLAY,
        label: learningStarted ? COMMON_MESSAGES.CONTINUE_LEARNING : COMMON_MESSAGES.START_LEARNING,
        onClick: handleLearnLinkClick,
        type: CTA_TYPE.PRIMARY,
      });
    }
  } else if (allUnlocked) {
    // multi store, all unlocked

    statusClass = STATUS.UNLOCKED;
    body = [COMMON_MESSAGES.MULTI_UNLOCKED];

    if (someDisabled) {
      body.push(COMMON_MESSAGES.SOME_UNAVAILABLE);
    } else if (!allLearningComplete) {
      body = [
        COMMON_MESSAGES.ALL_UNLOCKED,
        COMMON_MESSAGES.ADDITIONAL_LEARNING_AVAILABLE,
      ];
    }

    ctas.push({
      href: campaign.storeData?.storeLink,
      icon: CTA_ICON.CART,
      label: COMMON_MESSAGES.SHOP_DEALS,
      onClick: handleStoreLinkClick,
      type: CTA_TYPE.PRIMARY,
    });
    icon = ICON.UNLOCKED;
  } else {
    // multi store, some unlocked

    body = [
      COMMON_MESSAGES.SOME_UNLOCKED,
      COMMON_MESSAGES.LEARN_TO_UNLOCK_ALL,
    ];
    ctas.push({
      href: campaign.storeData?.storeLink,
      icon: CTA_ICON.CART,
      label: COMMON_MESSAGES.SHOP_DEALS,
      onClick: handleStoreLinkClick,
      type: CTA_TYPE.OUTLINE,
    });
    ctas.push({
      href: campaign.learnData?.nextModuleLink,
      icon: CTA_ICON.PLAY,
      label: COMMON_MESSAGES.CONTINUE_LEARNING,
      onClick: handleLearnLinkClick,
      type: CTA_TYPE.PRIMARY,
    });
    icon = ICON.UNLOCKED;
    statusClass = STATUS.UNLOCKED;

    if (someDisabled) {
      body.push(COMMON_MESSAGES.SOME_UNAVAILABLE);
      ctas[0].type = CTA_TYPE.PRIMARY;
      ctas[1].type = CTA_TYPE.OUTLINE;
    }
  }

  const sharedValues = {
    totalCount: storeCount,
    unavailableCount: disabledCount,
    unlockedCount,
  };

  return (
    <ActivityCta
      className={statusClass}
      icon={icon && (
        <>
          <i className={`exp-ux-small exp-ux-${icon}`} />
          <i className={`exp-ux-large exp-ux-${icon}`} />
          <svg className="cta-progress">
            <circle className="progress-base" />
            {icon === ICON.LOCKED && pctComplete >= 0 && (
              <circle
                className="progress"
                style={{ '--pctComplete': pctComplete }}
              />
            )}
          </svg>
        </>
      )}
      message={localizeOneOrMore(body, sharedValues)}
      title={title ? getCampaignMessage(title) : campaign.storeData.label}
      type={type}
    >
      {ctas.map((cta) => (
        <a
          className={`cta-button btn-${cta.type}`}
          href={`${cta.href}${(previewKey && cta.icon === CTA_ICON.CART) ? `?previewKey=${previewKey}` : ''}`}
          key={cta.href}
          onClick={cta.onClick}
        >
          <i className={`exp-ux-small exp-ux-${cta.icon}`} />
          <ExpLabel {...cta.label} namespace="messages/campaignMessages" />
        </a>
      ))}
    </ActivityCta>
  );
};

StoreCta.propTypes = {
  campaign: CampaignPropType.isRequired,
  onCtaClick: PropTypes.func,
  previewKey: PropTypes.string,
};

export default StoreCta;
