import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpLabel from 'exp-react-components/src/packages/localization/ExpLabel';
import ModalV2 from 'exp-react-components/src/packages/modalV2';

import { BrandAccessStatuses, BrandAccessDefaultLabels, BrandAccessDefaultHeadlines, BrandAccessDefaultExplainers } from '../brandAccessStatus';
import './brandAccessDetails.less';

const BrandAccessDetails = (props) => {
  const {
    brandAccessLevel,
    link,
    orgName,
    teamName,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!brandAccessLevel) {
    return null;
  }
  return (
    <div className="brand-access-details">
      <ExpLabel
        defaultMessage={BrandAccessDefaultLabels?.[brandAccessLevel]}
        messageKey={`community.access.${brandAccessLevel}`}
        className={`access-level ${brandAccessLevel}`}
      />
      {link}
      {isModalOpen && (
        <ModalV2
          handleClose={() => { setIsModalOpen(false); }}
        >
          <ModalV2.Header
            closeIcon
            title={(
              <ExpLabel
                defaultMessage="Reward Status"
                messageKey="community.summary.accessModal.title"
              />
            )}
          />
          <ModalV2.Body>
            <ExpLabel
              defaultMessage={BrandAccessDefaultHeadlines?.[brandAccessLevel]}
              messageKey={`community.summary.accessModal.headline.${brandAccessLevel}`}
              className="access-headline"
              tagName="h3"
            />
            <ExpLabel
              defaultMessage={BrandAccessDefaultExplainers?.[brandAccessLevel]}
              messageKey={`community.summary.accessModal.${brandAccessLevel}`}
              className="access-explainer"
              values={{ orgName, teamName }}
            />
          </ModalV2.Body>
          <ModalV2.Footer>
            <ExpLabel
              defaultMessage="Okay, got it"
              messageKey="community.summary.accessModal.cta"
              className="access-cta btn-primary"
              tagName="a"
              onClick={() => { setIsModalOpen(false); }}
            />
          </ModalV2.Footer>
        </ModalV2>
      )}
    </div>
  );
};

BrandAccessDetails.propTypes = {
  brandAccessLevel: PropTypes.oneOf(Object.values(BrandAccessStatuses)),
  link: PropTypes.node,
  orgName: PropTypes.string.isRequired,
  teamName: PropTypes.string,
};

export default BrandAccessDetails;
