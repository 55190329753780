import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortBy } from 'underscore';

import PreviewBar from 'ERC/previewMode/PreviewBar';
import ExpLabel from 'ERC/localization/ExpLabel';
import getExpMessage from 'ERC/localization/getExpMessage';

import { CampaignEvents } from '../../config/analytics';
import { TagPropType } from '../../config/propTypes';
import { sendEvent } from '../../../utils/analytics';
import { useQueryParams } from '../../../utils/reactRouterUtils';

/**
 * Campaign Preview Component
 *
 * @author jon.morris
 */
export const CampaignPreview = (props) => {
  const formRef = useRef();

  const {
    tagId: tagIdParam,
    ...queryParams
  } = useQueryParams();

  const tags = useMemo(() => {
    const sorted = sortBy(props.tags, 'sortOrder')
      .map((t) => ({
        ...t,
        previewable: !!t.content && (
          (t.content.trainingPlans && t.content.trainingPlans.length) ||
          (t.content.stores && t.content.stores.length)
        ), // Previewable if the tag has either training plans or stores
        published: ['ACTIVE', 'PENDING_UPDATE'].indexOf(t.status) !== -1,
      }));

    return {
      published: sorted.filter((t) => t.published),
      unpublished: sorted.filter((t) => !t.published),
    };
  }, [props.tags]);

  const renderTag = (tag) => (
    <option disabled={!tag.previewable} key={`tag-${tag.tagId}`} value={tag.tagId}>
      {tag.name}
    </option>
  );

  // Default to the selected tagId or the only one if there's only 1
  const selectedTagId = props.selectedTagId || +tagIdParam
    || (props.tags.length === 1 && props.tags[0]) || null;

  return (
    <PreviewBar>
      {props.tags.length ? (
        <form
          className="preview-form"
          method="GET"
          ref={formRef}
        >
          {/* Construct hidden inputs to the preserve query parameters */}
          {Object.keys(queryParams)
            .map((key) => (
              <input
                key={key}
                name={key}
                type="hidden"
                value={queryParams[key]}
              />
            ))}

          <div className="preview-form-field preview-tag-field">
            <label className="preview-field-label" htmlFor="tag-field">
              <ExpLabel
                defaultMessage="Select Audience:"
                messageKey="campaign.preview.tag-label"
                namespace="messages/campaignMessages"
              />
            </label>
            <select
              id="tag-field"
              name="tagId"
              onChange={(e) => {
                const { value } = e.target;
                sendEvent(CampaignEvents.PREVIEW.TAG_SELECT, {
                  selectedTagId: value,
                });

                formRef.current?.submit();
              }}
              value={selectedTagId || ''}
            >
              <option
                disabled={props.tags.length === 1}
                value=""
              >
                {getExpMessage({
                  defaultMessage: '- All -',
                  messageKey: 'campaign.preview.tag-select-all',
                  namespace: 'messages/campaignMessages',
                })}
              </option>
              <optgroup
                label={getExpMessage({
                  defaultMessage: 'Published',
                  messageKey: 'campaign.preview.tag-group.published',
                  namespace: 'messages/campaignMessages',
                })}
              >
                {tags.published.map(renderTag)}
              </optgroup>
              <optgroup
                label={getExpMessage({
                  defaultMessage: 'Unpublished',
                  messageKey: 'campaign.preview.tag-group.unpublished',
                  namespace: 'messages/campaignMessages',
                })}
              >
                {tags.unpublished.map(renderTag)}
              </optgroup>
            </select>
          </div>
        </form>
      ) : null}
    </PreviewBar>
  );
};

CampaignPreview.defaultProps = {
  selectedTagId: null,
  tags: [],
};

CampaignPreview.propTypes = {
  selectedTagId: PropTypes.number,
  tags: PropTypes.arrayOf(TagPropType),
};

const mapStateToProps = (state) => ({
  selectedTagId: state.campaign.previewConfig?.tagId,
  tags: state.campaign.previewConfig?.tags,
});

export default connect(mapStateToProps)(CampaignPreview);
