// All available organization client statuses
const OrgClientStatuses = {
  INACTIVE: 0,
  ACTIVE: 1,
  PROSPECTIVE: 2,
  COMING_SOON: 3,
  DEPARTED: 4,
};

// Maps the above int ids to their text names (admin use only)
export const OrgClientStatusNames = {
  [OrgClientStatuses.INACTIVE]: 'Inactive',
  [OrgClientStatuses.ACTIVE]: 'Active',
  [OrgClientStatuses.PROSPECTIVE]: 'Prospective',
  [OrgClientStatuses.COMING_SOON]: 'Coming Soon',
  [OrgClientStatuses.DEPARTED]: 'Departed',
};

export default OrgClientStatuses;
