import React from 'react';
import PropTypes from 'prop-types';

import ExpLabel from 'ERC/localization/ExpLabel';
import ExternalableLink from 'ERC/utilities/components/ExternalableLink';

import './SeeAllCard.less';

const SeeAllCard = (props) => (
  <div className={`card-wrapper see-all-card${props.className ? ` ${props.className}` : ''}`}>
    <ExternalableLink
      className="card"
      href={props.href}
      onClick={props.onClick}
      to={props.to}
    >
      <span className="card-content">
        <span className="icon-wrap">
          {props.icon || (
            <i className="exp-ux-large exp-ux-chevron" />
          )}
        </span>
        {props.label || (
          <ExpLabel
            className="see-all-label"
            defaultMessage="See all"
            messageKey="home.feed.seeAll"
          />
        )}
      </span>
    </ExternalableLink>
  </div>
);

SeeAllCard.defaultProps = {
  className: null,
  href: null,
  icon: null,
  label: null,
  onClick: () => {},
};

SeeAllCard.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  to: PropTypes.any.isRequired,
};

export default SeeAllCard;
