import React from 'react';

import CampaignCard from 'ERC/campaign/CampaignCard';
import FlashDealPreviewCard from 'ERC/campaign/FlashDealPreviewCard';
import GenericCampaignCard from 'ERC/campaign/GenericCampaignCard';
import PSPCampaignCard from 'ERC/campaign/PSPCampaignCard';
import SocialCampaignCard from 'ERC/campaign/SocialCampaignCard';
import SurveyCampaignCard from 'ERC/campaign/SurveyCampaignCard';

/**
 * Generates a hopefully unique key for a given card
 * @param {Object} card
 * @param {number=} card.id
 * @param {string=} card.feedItemType
 * @param {string=} card.key
 * @param {string=} card.reportingType
 * @param {string=} card.type
 * @returns {string} A unique key string for the given card
 */
export const getKeyForCard = (card) => (
  card.key || `card-${card.reportingType}-${card.feedItemType}-${card.type}-${card.id}`
);

/**
 * @returns {SocialCampaignCard|CampaignCard}
 */
export const renderCampaignCard = (options) => (
  // For multi-brand campaigns, use the SocialCampaignCard view
  options.card.type === 'MULTI' ? (
    <SocialCampaignCard {...options} />
  ) : (
    <CampaignCard {...options} />
  ));

export const renderFlashDealPreviewCard = (options) => (
  <FlashDealPreviewCard {...options} />
);

export const renderGenericCard = (options) => (
  <GenericCampaignCard {...options} />
);

export const renderPSPCard = (options) => (
  <PSPCampaignCard {...options} />
);

export const renderSocialCampaignCard = (options) => (
  <SocialCampaignCard {...options} />
);

export const renderSurveyCard = (options) => (
  <SurveyCampaignCard
    removeCard={options.removeSurveyCard}
    {...options}
  />
);

/**
   * Renders an array of card data into card components
   * @param {Object[]} cardsToRender Array of card data
   * @param {Object} opts Options to pass to the cards
   * @param {function} opts.analyticsSendEvent
   * @param {Object[]} opts.cardOverrides
   * @param {bool} opts.disabled
   * @param {string[]} opts.excludedCards
   * @param {number} opts.maxBucketSize
   * @param {function} opts.onItemView
   * @param {Object} opts.tailCard
   */
const renderCards = (cardsToRender, opts = {}) => {
  const { onItemView, ...cardOptions } = opts;

  const cards = cardsToRender
    .filter(currentCard => currentCard && !cardOptions.excludedCards?.includes(`${currentCard.id}`))
    .slice(0, cardOptions.maxBucketSize || cardsToRender.length)
    .map((currentCard, cardIndex) => {
      let card = { ...currentCard };

      // apply card overrides if available
      const override = cardOptions.cardOverrides?.[card.id];
      if (override) {
        card = { ...currentCard, ...override };
      }

      const options = { ...cardOptions };
      if (card.analyticsSendEvent) {
        // Hoist up a custom analytic event publisher for the card
        options.analyticsSendEvent = card.analyticsSendEvent;
      }

      const params = {
        card,
        cardIndex,
        disabled: opts.disabled,
        key: getKeyForCard(card),
        onView: onItemView ? () => onItemView(card, cardIndex) : undefined,
        ...options,
      };

      // Use a custom render method on the card if one is specified
      if (card.render && typeof card.render === 'function') {
        return card.render(params);
      }

      const type = card.reportingType || card.feedItemType || null;

      if (card.feedItemType === 'PSP' || card.placements?.some((p) => p.placement === 'PSP')) {
        return renderPSPCard(params);
      }

      if (card.placements?.some((p) => p.placement === 'FLASH_PREVIEW')) {
        return renderFlashDealPreviewCard(params);
      }

      switch (type) {
        case 'CREATE_BUZZ':
        case 'DRIVE_TRAFFIC':
        case 'GENERATE_CONTENT':
        case 'GENERATE_AND_SHARE':
          return renderSocialCampaignCard(params);
        case 'GENERIC':
          return renderGenericCard(params);
        case 'SURVEY':
          return renderSurveyCard(params);
        default:
          return renderCampaignCard(params);
      }
    })
    .filter(currentCard => !!currentCard);

  if (cardOptions.tailCard) {
    cards.push(cardOptions.tailCard);
  }

  return cards;
};

/**
 * Handles rendering cards in a reusable/exportable way
 */
const CardRenderer = {
  getKeyForCard,
  renderCampaignCard,
  renderCards,
  renderGenericCard,
  renderPSPCard,
  renderSocialCampaignCard,
  renderSurveyCard,
};

export default CardRenderer;
