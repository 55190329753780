import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import { useFollowedUsers } from 'ERC/following/hooks';
import ExpLabel from 'ERC/localization/ExpLabel';
import { useIsMediaMD, useIsMediaSM } from 'ERC/hooks';

import CommunityActionsDispatch from '../../redux/actions';
import MemberList from './memberList';

import './communityMembersSection.less';

/**
 * Community Members Section
 */
const CommunityMembersSection = (props) => {
  const { path, url } = useRouteMatch();
  const isMD = useIsMediaMD();
  const isSM = useIsMediaSM();

  // Ensure the followed users are loaded
  useFollowedUsers();

  return (
    <section className="community-body community-container community-members">
      <Switch>
        <Route exact path={path}>
          <MemberList
            expandable={props.inlineFollowList}
            expandedListUrl={!isSM || !props.inlineFollowList ? `${url}/followed` : null}
            fetchPage={props.fetchFollowedMembers}
            heading={(
              <ExpLabel
                defaultMessage="Members You Follow"
                messageKey="community.members.followed"
              />
            )}
            maxItems={isSM && !isMD ? 7 : 8}
            layout="gallery"
            members={props.followedMembers}
            myUuid={props.user.uuid}
            source="followed"
          />
          <MemberList
            fetchPage={props.fetchMembers}
            heading={(
              <ExpLabel
                defaultMessage="All Members"
                messageKey="community.members.all"
              />
            )}
            members={props.members}
            myUuid={props.user.uuid}
            source="all"
          />
        </Route>
        {!isSM || !props.inlineFollowList ? (
          <Route path={`${path}/followed`}>
            <Link className="back-link" to={url}>
              <i className="exp-ux-small exp-ux-arrow" />
              <ExpLabel
                defaultMessage="All Members"
                messageKey="community.members.all"
              />
            </Link>
            <MemberList
              fetchPage={props.fetchFollowedMembers}
              heading={(
                <ExpLabel
                  defaultMessage="Members You Follow"
                  messageKey="community.members.followed"
                />
              )}
              members={props.followedMembers}
              myUuid={props.user.uuid}
              source="followed"
            />
          </Route>
        ) : null}
        <Redirect to={url} />
      </Switch>
    </section>
  );
};

CommunityMembersSection.defaultProps = {
  followedMembers: null,
  inlineFollowList: false,
  members: null,
  user: {},
};

CommunityMembersSection.propTypes = {
  fetchFollowedMembers: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  followedMembers: PropTypes.shape({
    currentPage: PropTypes.number,
    hasMore: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    pageSize: PropTypes.number,
    stale: PropTypes.bool,
    total: PropTypes.number,
  }),
  inlineFollowList: PropTypes.bool,
  members: PropTypes.shape({
    currentPage: PropTypes.number,
    hasMore: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    pageSize: PropTypes.number,
    total: PropTypes.number,
  }),
  user: PropTypes.shape({
    uuid: PropTypes.string,
  }),
};

const mapStateToProps = (state) => state.community;

export default connect(mapStateToProps, CommunityActionsDispatch)(CommunityMembersSection);
