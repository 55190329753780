import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const loadSocialConnections = createAsyncThunk(
  'user/social/connections/load',
  async () => window.ExpSocialConnector.getUsersSocialConnections(),
);

export const loadSocialConnection = createAsyncThunk(
  'user/social/connection/load',
  async ({ providerKey }, { dispatch }) => {
    const { payload } = await dispatch(loadSocialConnections());
    return payload?.find((c) => c.providerKey === providerKey);
  },
);

export const connectSocial = createAsyncThunk(
  'user/social/connect',
  async ({ providerType }, { rejectWithValue }) => {
    if (!window.ExpSocialConnector?.connectAccount) {
      throw new Error('ExpSocialConnector not found');
    }

    return new Promise((resolve, reject) => {
      window.ExpSocialConnector.connectAccount(providerType)
        .then(resolve, (e) => reject(rejectWithValue(e)));
    });
  },
);

export const loadUser = createAsyncThunk('user/load', async () => window.Exp.user.load());

export const reloadUser = createAsyncThunk('user/reload', async (_, { dispatch }) => {
  // Reset the user cache
  window.Exp.user.resetCache();

  // Load the user again
  const { payload } = await dispatch(loadUser());
  return payload;
});

export const defaultState = (user = {}) => ({ user });

const slice = createSlice({
  name: 'user',
  initialState: defaultState(),
  extraReducers: {
    [loadSocialConnections.fulfilled]: (state, action) => {
      state.connections = action.payload;
    },

    [loadUser.pending]: (state) => {
      state.loadError = null;
      state.loadPending = true;
    },
    [loadUser.fulfilled]: (state, action) => {
      state.loadPending = false;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [loadUser.rejected]: (state, action) => {
      state.loadError = action.error;
      state.loadPending = false;
    },
  },
});

export const useUser = () => useSelector((state) => state.user.user);

export default slice.reducer;
