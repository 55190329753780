import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { BREAKPOINTS } from 'ERC/utilities/responsiveUtils';

import './communityHero.less';

/**
 * Community Hero Component
 */
const CommunityHero = (props) => (
  <div
    className={`community-hero${props.images ? '' : ' empty-hero'}${
      props.className ? ` ${props.className}` : ''}`}
  >
    {props.images ? (
      <picture>
        {props.images.DESKTOP?.uri ? (
          <source srcSet={props.images.DESKTOP.uri} media={`(min-width: ${BREAKPOINTS.lg}px`} />
        ) : null}
        {props.images.TABLET?.uri ? (
          <source srcSet={props.images.TABLET.uri} media={`(min-width: ${BREAKPOINTS.sm}px`} />
        ) : null}
        {props.images.MOBILE?.uri ? (
          <source srcSet={props.images.MOBILE.uri} media={`(max-width: ${BREAKPOINTS.sm - 1}px`} />
        ) : null}
        {props.images.HERO?.uri ? (
          <img alt="" src={props.images.HERO.uri} />
        ) : null}
      </picture>
    ) : null}
  </div>
);

CommunityHero.defaultProps = {
  className: '',
  images: null,
};

CommunityHero.propTypes = {
  className: PropTypes.string,
  images: PropTypes.shape({
    DESKTOP: PropTypes.shape({
      uri: PropTypes.string,
    }),
    HERO: PropTypes.shape({
      uri: PropTypes.string,
    }),
    MOBILE: PropTypes.shape({
      uri: PropTypes.string,
    }),
    TABLET: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
};

export default memo(CommunityHero);
