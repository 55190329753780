import PropTypes from 'prop-types';
import { useEffect } from 'react';

const LIBRARY_CHECK_INTERVAL = 100;
const EditCategoriesModal = (props) => {
  const { onClose, onSuccess } = props;
  useEffect(() => {
    const waitForLibrary = (remainingAttempts = 0) => new Promise((resolve, reject) => {
      if (window.ExpCategorySelector) {
        // Library is present so resolve out
        resolve();
      } else if (remainingAttempts > 0) {
        // Queue up another check after a delay
        window.setTimeout(() => {
          resolve(waitForLibrary(remainingAttempts - 1));
        }, LIBRARY_CHECK_INTERVAL);
      } else {
        reject();
      }
    });

    waitForLibrary(20)
      .then(() => new Promise((resolve) => {
        // Show the category selector
        window.ExpCategorySelector
          .once('close', ({ context }) => {
            // Resolve the promise whenever the modal closes
            resolve(context.categories);
          })
          .show();
      }).then((categories) => {
        if (categories?.length) {
          onSuccess(categories);
        } else {
          onClose();
        }
      }));
  }, [onClose, onSuccess]);

  return null;
};

EditCategoriesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default EditCategoriesModal;
