import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'ERC/ugc/api';

export const UGC_PAGE_SIZE = 24;

export const fetchCategories = createAsyncThunk(
  'ugc/categories/fetch',
  async () => api.fetchActiveCategories(),
);

export const fetchPhoto = createAsyncThunk(
  'ugc/photo/fetch',
  async (id) => api.fetchPhoto(id),
);

export const searchPhotos = createAsyncThunk(
  'ugc/photos/search',
  async ({
    category,
    earliestTime,
    featured = false,
    reviewStatus,
    size = UGC_PAGE_SIZE,
    userUuid,
  }) => api.fetchPhotos({
    category,
    featured,
    includeFlagged: true,
    moreBefore: earliestTime,
    reviewStatus,
    size,
    userUUID: userUuid,
  }),
);

export const defaultState = () => ({
  categoryId: [],
  categoriesList: [],
  featuredFilter: false,
  fetchPending: false,
  ugcDeleteSuccess: false,
  hasMore: true,
  ugcContent: null,
});

const slice = createSlice({
  name: 'ugcAdmin',
  initialState: defaultState(),
  reducers: {
    deletePhoto: (state, action) => {
      const { id } = action.payload;
      state.ugcDeleteSuccess = true;
      state.ugcContent = state.ugcContent.filter((ugc) => ugc.id !== id);
    },
    setCategory: (state, action) => {
      state.categoryId = action.payload;
    },
    setFeaturedFilter: (state, action) => {
      state.featuredFilter = action.payload;
    },
    toggleFeatured: (state, action) => {
      const { id, tags } = action.payload;
      const item = state.ugcContent.find((ugc) => ugc.id === id);
      if (item) {
        item.tags = tags;
      }
    },
  },
  extraReducers: {
    [fetchCategories.fulfilled]: (state, action) => {
      state.categoriesList = action.payload;
    },

    [fetchPhoto.fulfilled]: (state, action) => {
      state.fetchItemPending = false;
      state.hasMore = false;
      state.ugcContent = [action.payload];
    },
    [fetchPhoto.pending]: (state) => {
      state.fetchItemPending = true;
      state.hasMore = false;
    },

    [searchPhotos.fulfilled]: (state, action) => {
      const { earliestTime } = action.meta.arg;
      const { results, totalPossibleResults } = action.payload;

      state.fetchPending = false;
      state.ugcContent = earliestTime ? state.ugcContent.concat(results) : results;
      state.hasMore = state.ugcContent.length < totalPossibleResults;
      state.totalResults = totalPossibleResults;
    },
    [searchPhotos.pending]: (state) => {
      state.fetchPending = true;
    },
    [searchPhotos.rejected]: (state) => {
      state.fetchPending = false;
    },
  },
});

export const {
  deletePhoto,
  setCategory,
  setFeaturedFilter,
  toggleFeatured,
} = slice.actions;

export default slice.reducer;
