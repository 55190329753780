import React, { useCallback, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ExpLabel from 'ERC/localization/ExpLabel';

import OrgClientStatuses from '../orgClientStatuses';
import { fetchTeaserTeams } from '../redux/actions';
import './teaserTeams.less';
import TeaserTeamCarousel from './TeaserTeamCarousel';
import { useQueryParam } from '../../utils/reactRouterUtils';
import { PageTypesByType } from '../PageTypes';

const TeaserTeams = ({ isAcquisition }) => {
  const dispatch = useDispatch();
  const org = useSelector((state) => state.community.org);
  const pageId = useSelector((state) => state.community.id);
  const pageName = useSelector((state) => state.community.name);
  const pageType = useSelector((state) => state.community.pageType);
  const teamTitle = useSelector((state) => state.community.teamTitle);
  const teaserTeams = useSelector((state) => state.community.teaserTeams);
  const autoOpen = !!useQueryParam('search');

  useEffect(() => {
    // Ensure the teaser teams are loaded
    dispatch(fetchTeaserTeams({ pageId }));
  }, [dispatch, pageId]);

  const items = useMemo(() => (teaserTeams || [])
    .filter((team) => team.applicationsEnabled && (team.publicApplicationFormId || team.teamType === 'RETAIL'))
    .map((team) => ({
      ...team,
      pageUrl: (team.teamType === 'PROMOTIVE' && team.teamKey && `/group/${team.teamKey}`)
        || (team.teamType === 'RETAIL' && team.orgKey && `/retailer/${team.orgKey}`)
        || undefined,
    })), [teaserTeams]);

  const findTeams = useCallback(() => {
    const teamIds = items.map(({ teamId }) => teamId);
    window.ExpManageGroups.show({
      searchTeams: teamIds,
    });
  }, [items]);

  useEffect(() => {
    if (autoOpen && items.length) {
      findTeams();
    }
  }, [findTeams, items, autoOpen]);

  const carouselTitle = useMemo(() => {
    if (pageType === PageTypesByType.TEAM_CLASS.type) {
      return teamTitle || (
        <ExpLabel
          defaultMessage="Qualify for rewards on ExpertVoice by verifying with organizations like these"
          messageKey="community.team.teamsTitle"
        />
      );
    }
    if (!isAcquisition) {
      return (
        <>
          <ExpLabel
            className="section-title authenticated"
            defaultMessage="Who qualifies for rewards?"
            messageKey="community.teaserTeams.authenticated.title"
            tagName="h3"
          />
          <ExpLabel
            className="section-label"
            defaultMessage="Qualify for rewards on ExpertVoice by verifying with these organizations. Individual access and rewards might vary."
            messageKey="community.teaserTeams.authenticated.label"
            tagName="p"
          />
        </>
      );
    }
    return null;
  }, [isAcquisition, pageType, teamTitle]);

  if (pageType !== PageTypesByType.TEAM_CLASS.type && (!items.length || org?.orgStatus !== 1 ||
    org?.orgClientStatus !== OrgClientStatuses.ACTIVE)) {
    // Only show when teaser teams exist & the org is active, or team_class page
    return null;
  }
  if (!items.length > 0) {
    return null;
  }

  return (
    <div className="acquisition-teaser-teams">
      <div className="community-container">
        {isAcquisition && pageType !== PageTypesByType.TEAM_CLASS.type && (
          <ExpLabel
            className="section-title"
            defaultMessage="{name} connects with experts from a wide range of trusted organizations"
            messageKey="community.teaserTeams.title"
            tagName="h3"
            values={{ name: pageName }}
          />
        )}
        <TeaserTeamCarousel
          items={items}
          title={carouselTitle}
        />
        {pageType !== PageTypesByType.TEAM_CLASS.type && (isAcquisition ? (
          <ExpLabel
            className="teaser-team-prompt"
            defaultMessage="Verify with one of the organizations above or {signUpLink} to find your organization."
            messageKey="community.teaserTeams.signUpPrompt"
            tagName="p"
            values={{
              signUpLink: (
                <button
                  className="link-primary link-inline"
                  onClick={() => {
                    window.ExpOnboarding?.signUp();
                  }}
                  type="button"
                >
                  <ExpLabel
                    defaultMessage="sign up"
                    messageKey="community.teaserTeams.signUp"
                  />
                </button>
              ),
            }}
          />
        ) : (
          <div className="explore-and-support">
            <a
              className="explore-prompt btn-outline"
              href="/user/qualify"
              type="button"
            >
              <ExpLabel
                defaultMessage="Explore suggestions"
                messageKey="community.teaserTeams.authenticated.cta"
              />
            </a>
            <a className="support-link link-primary" href="https://expert-support.expertvoice.com/hc/en-us/categories/360000070664-Brand-Access-101">
              <ExpLabel
                defaultMessage="Visit Support"
                messageKey="community.teaserTeams.authenticated.support"
              />
              <i className="exp-ux-chevron exp-ux-small" />
            </a>
          </div>
        ))}
        {pageType === PageTypesByType.TEAM_CLASS.type && (
          <ExpLabel
            className="team-class-disclaimer"
            defaultMessage="*Brand access is based on your qualifications, creating a shopping experience tailored to you."
            messageKey="community.teaserTeams.teamClass.disclaimer"
            tagName="p"
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(TeaserTeams);

TeaserTeams.propTypes = {
  isAcquisition: PropTypes.bool,
};
