import React from 'react';
import PropTypes from 'prop-types';

import ExpLabel from 'ERC/localization/ExpLabel';
import { useIsMediaSM } from 'ERC/hooks';

import { EngagementPropType } from '../../config/propTypes';
import './stepItem.less';

const StepItem = (props) => {
  const {
    activity: { engagement: { complete, enabled } },
    className,
    cta,
    icon,
    mobileAction,
    secondaryText,
    titleText,
  } = props;

  let renderedIcon = icon;

  if (typeof icon === 'string') {
    renderedIcon = (
      <>
        <i className={`type-icon ${icon} exp-ux-large mobile`} />
        <i className={`type-icon ${icon} exp-ux-xlarge desktop`} />
      </>
    );
  }

  const isSM = useIsMediaSM();

  return (
    <div
      className={`step ${className}`}
      data-enabled={enabled}
      onClick={() => !isSM && mobileAction?.()}
    >
      <div className="step-item">
        {renderedIcon}
        <div className="step-details">
          <span className="step-title">
            {titleText}
          </span>
          {enabled && secondaryText && (
            <span className="step-subtext">
              {secondaryText}
            </span>
          )}
          {!enabled && (
            <span className="unavailable-text">
              <i className="exp-ux-locked exp-ux-small desktop" />
              <ExpLabel
                defaultMessage="Unavailable"
                messageKey="campaign.plc.steps.unavailable"
                namespace="messages/campaignMessages"
              />
            </span>
          )}
          {cta && enabled && (
            <div className="step-buttons">
              {cta}
            </div>
          )}
        </div>

        {props.showCompletionIcon ? (
          complete ? (
            <>
              <i className="exp-ux-check-circle exp-ux-large mobile" />
              <i className="exp-ux-check-circle exp-ux-xlarge desktop" />
            </>
          ) : enabled ? (
            <>
              <i className="exp-ux-chevron exp-ux-medium mobile" />
              <i className="exp-ux-chevron exp-ux-large desktop" />
            </>
          ) : (
            <i className="exp-ux-locked exp-ux-large mobile" />
          )
        ) : null}
      </div>

      {props.children}
    </div>
  );
};

StepItem.defaultProps = {
  children: null,
  className: null,
  cta: null,
  mobileAction: () => {},
  showCompletionIcon: true,
};

StepItem.propTypes = {
  children: PropTypes.node,
  activity: PropTypes.shape({
    engagement: EngagementPropType.isRequired,
  }).isRequired,
  className: PropTypes.string,
  cta: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  mobileAction: PropTypes.func,
  secondaryText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showCompletionIcon: PropTypes.bool,
  titleText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default StepItem;
