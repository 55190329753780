import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Carousel from 'ERC/carousel';

import CommunityActionsDispatch from '../redux/actions';
import CardRenderer from '../../common/CardRenderer';
import { sendEvent } from '../../utils/analytics';
import ErrorBoundary from '../../common/ErrorBoundary';

import './campaignCardCarousel.less';

/**
 * Campaign Card Carousel
 */
const CampaignCardCarousel = (props) => (
  <ErrorBoundary>
    <Carousel
      className={props.isBlocked ? 'is-blocked' : ''}
      title={props.title}
    >
      {CardRenderer.renderCards(props.cards, {
        analyticsSendEvent: sendEvent,
        removeSurveyCard: props.removeSurveyCard,
      })}
    </Carousel>
  </ErrorBoundary>
);

CampaignCardCarousel.defaultProps = {
  cards: [],
  className: '',
  isBlocked: false,
  title: null,
};

CampaignCardCarousel.propTypes = {
  cards: PropTypes.array,
  className: PropTypes.string,
  isBlocked: PropTypes.bool,
  removeSurveyCard: PropTypes.func.isRequired,
  title: PropTypes.node,
};

export default connect(() => ({}), CommunityActionsDispatch)(CampaignCardCarousel);
